import Icon from '../../icons/Icon';

const HeartFace = () => {
	return (
		<Icon label={'Heart Face'}>
			<svg width='32' height='29' viewBox='0 0 32 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M3.40159 14.2999C1.02572 11.3565 1.08978 7.13646 3.5539 4.2665L3.79665 3.98376C6.74408 0.550886 12.0401 0.486514 15.0751 3.84472C15.5694 4.39165 16.4292 4.39311 16.9247 3.84485L16.1828 3.17434L16.9247 3.84484C19.9289 0.520761 25.186 0.646027 28.0284 4.10942L28.2989 4.439C30.5885 7.2287 30.6322 11.2346 28.4043 14.0736L19.1453 25.8722C17.5303 27.9302 14.4208 27.9515 12.7777 25.9159L3.40159 14.2999Z'
					stroke='black'
					strokeWidth='2'
				/>
			</svg>
		</Icon>
	);
};

export default HeartFace;
