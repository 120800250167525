import { forwardRef, ReactNode, useState } from 'react';
import { AnimatePresence, m, MotionProps } from 'framer-motion';
import cn from 'classnames';
import { Caption, Chevron, Flex, Img, Paragraph } from '@components';
import { accordianHeightExpansion } from '@utils/motions';
import styles from './AccordianCell.module.scss';

type AccordianCellProps = {
	initiallyExpanded?: boolean;
	title: string;
	/**
	 * 2 booleans added for loading purposes to prevent layout shift
	 */
	hasImage?: boolean;
	hasNotice?: boolean;
	imageSrc?: string;
	description: string;
	/**
	 * Optional notice to display above the image and description
	 */
	notice?: string;
	children: ReactNode;
	className?: string;
};

const SkeletonTitle = () => <div className={styles['skeleton__title']} />;
const SkeletonImage = () => <div className={styles['skeleton__image']} />;
const SkeletonDescription = () => <div className={styles['skeleton__description']} />;
const SkeletonNotice = () => <div className={styles['skeleton__notice']} />;

const AccordianCell = forwardRef<HTMLDivElement, AccordianCellProps>(
	(
		{
			initiallyExpanded = false,
			title,
			hasImage = false,
			imageSrc,
			hasNotice = false,
			notice = null,
			description,
			children,
			className = '',
		},
		ref
	) => {
		const [expanded, setExpanded] = useState(initiallyExpanded);
		return (
			<m.div ref={ref} className={cn(styles.container, className)}>
				<Flex gap={3} align='start' justify='between' fullWidth style={{ marginBottom: '0.8rem' }}>
					{title ? <Caption>{title}</Caption> : <SkeletonTitle />}
					<div
						tabIndex={0}
						onKeyDown={e => {
							if (e.key !== 'Enter') {
								return;
							}
							setExpanded(!expanded);
						}}
						onClick={() => setExpanded(!expanded)}
						className={styles.iconCircle}
						data-rxss-open-input-accordian={title}
					>
						<Chevron direction={expanded ? 'up' : 'down'} />
					</div>
				</Flex>
				{notice ? (
					<Paragraph style={{ marginBottom: '0.8rem' }}>{notice}</Paragraph>
				) : hasNotice ? (
					<SkeletonNotice />
				) : null}
				<AnimatePresence>
					{expanded && (
						// Sneaky casting hack so we can animate position (shouldn't work but looks cleaner)
						<m.div {...(accordianHeightExpansion as MotionProps)}>
							{imageSrc ? (
								<Img src={imageSrc} noSrcset className={styles.image} />
							) : hasImage ? (
								<SkeletonImage />
							) : null}
							{description ? (
								<Paragraph style={{ marginTop: '0.8rem' }}>{description}</Paragraph>
							) : (
								<SkeletonDescription />
							)}
						</m.div>
					)}
				</AnimatePresence>
				<Flex gap={3} justify='between' style={{ marginTop: '1.8rem' }}>
					{children}
				</Flex>
			</m.div>
		);
	}
);

AccordianCell.displayName = 'AccordianCell';

export default AccordianCell;
