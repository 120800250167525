import { useEffect, useMemo, useRef, useState } from 'react';

/**
 * Hook to check if an element is on screen.
 *
 * @returns {Object} - Returns the ref of the element and a boolean to check if it is on screen.
 */

export default function useOnScreen() {
	const ref = useRef();
	const [isOnScreen, setIsOnScreen] = useState(false);

	const observer = useMemo(() => {
		if (typeof window !== 'undefined') {
			return new IntersectionObserver(([entry]) => setIsOnScreen(entry.isIntersecting));
		}
	}, [ref]);

	useEffect(() => {
		if (ref.current) {
			observer.observe(ref.current);
		}
		return () => observer.disconnect();
	}, [ref.current]);

	return { ref, isOnScreen };
}
