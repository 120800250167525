import Icon from '../../icons/Icon';

const SquareFace = () => {
	return (
		<Icon label='Square Face'>
			<svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M24.0402 1C25.6749 1 27 2.32515 27 3.9598V23.0503C27 25.2316 25.2316 27 23.0503 27H3.95979C2.32514 27 1 25.6749 1 24.0402V4.94974C1 2.76836 2.76836 1 4.94975 1H24.0402Z'
					stroke='black'
					strokeWidth='2'
				/>
			</svg>
		</Icon>
	);
};

export default SquareFace;
