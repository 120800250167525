import { useQueryClient } from '@tanstack/react-query';
import { CustomerActivateInput, Mutation } from '@ts/shopify-storefront-api';
import { PairError } from '@services/errors';
import customerActivateMutation from '@services/shopify/mutations/customer-activate';
import { getDaysUntil } from '@utils/dates';
import { setCustomerToken } from '@services/shopify/customer-token';
import fetchStorefrontApi from '../fetch-storefront-api';
import throwUserErrors from '../throw-user-errors';

const useActivateCustomer = () => {
	const queryClient = useQueryClient();
	return async (id: string, input: CustomerActivateInput) => {
		if (!(id && input.activationToken && input.password))
			throw new PairError({ message: 'An id, token, and password are required to activate' });
		const mutation: Mutation = await fetchStorefrontApi(
			customerActivateMutation,
			{
				variables: {
					id,
					input,
				},
			},
			null
		);
		const { customerActivate } = mutation;

		throwUserErrors(customerActivate?.customerUserErrors);

		const customerAccessToken = customerActivate?.customerAccessToken;
		const accessToken = customerAccessToken?.accessToken;
		const tokenExpiration = customerAccessToken?.expiresAt;

		const daysUntilExpiration = getDaysUntil(new Date(Date.now()), new Date(tokenExpiration));

		if (accessToken) {
			setCustomerToken(accessToken, daysUntilExpiration);
			queryClient.resetQueries();
		}

		return null;
	};
};

export default useActivateCustomer;
