import { memo } from 'react';
import { Heading } from '@components';
import { useContentfulAsset } from '@services/contentful/hooks';
import { INFO_MODULE_DATA } from '@utils/constants/info-module';
import CardValueProposition from '../../contentful/CardValueProposition';
import styles from './InfoModule.module.scss';

const InfoModule = ({ journey }) => {
	const title = INFO_MODULE_DATA[journey].title;
	const LensLineUp = useContentfulAsset({
		id: INFO_MODULE_DATA[journey].id,
		query: {
			'content_type': 'componentCardsValueProposition',
			'fields.handle': 'home-page-lens-lineup',
		},
		asNormalized: true,
	});

	if (!LensLineUp?.data) {
		return null;
	}

	return (
		<div className={styles['container']} data-section-name='lens-offerings'>
			<Heading tag='h4' className={styles.heading}>
				{title}
			</Heading>
			<CardValueProposition noPadding shouldExcludeCopy {...LensLineUp.data[0]} />
		</div>
	);
};

export default memo(InfoModule);
