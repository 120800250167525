/**
 * Flattens a list of React Providers
 *
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ProviderTree = (providers: any[]) => {
	if (providers.length === 1) {
		return providers[0][0];
	}
	const [A, paramsA] = providers.shift();
	const [B, paramsB] = providers.shift();

	return ProviderTree([
		[
			({ children }) => (
				<A {...(paramsA || {})}>
					<B {...(paramsB || {})}>{children}</B>
				</A>
			),
		],
		...providers,
	]);
};

export default ProviderTree;
