import { FieldValues, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { WheelPicker } from '@components';
import { RXSS_TOOL_VALUE_RANGES } from '@utils/constants/rxss';
import { decipherPrism } from '@utils/rxss';
import { RxssPomsPayload } from '@ts/rxss';

type WheelPickerArmsProps = {
	activeInput: {
		id: keyof RxssPomsPayload;
		label: string;
		values?: (typeof RXSS_TOOL_VALUE_RANGES)[keyof typeof RXSS_TOOL_VALUE_RANGES];
		index?: number;
		centerInstruction?: boolean;
	};
	setValue: UseFormSetValue<FieldValues>;
	getValues: UseFormGetValues<FieldValues>;
};

const WheelPickerArms = ({ activeInput, getValues, setValue }: WheelPickerArmsProps) => {
	if (!activeInput) return null;
	if (!(activeInput.id as string).includes('prism')) {
		return (
			<WheelPicker.Arm
				values={activeInput?.values?.map(({ copy }) => copy) || []}
				defaultValue={getValues(activeInput?.id as string)}
				onChange={newValue => {
					setValue(activeInput?.id as string, newValue, { shouldDirty: true, shouldValidate: true });
				}}
				instruction='Make a Selection'
				centerInstruction={activeInput?.centerInstruction}
				dataTags={{ 'data-wheel-picker-values': activeInput.id }}
			/>
		);
	}

	const leftPrism = (activeInput.id as string).includes('left');
	const horizontalPrism = (activeInput.id as string).includes('horizontal');
	const baseValues = horizontalPrism ? RXSS_TOOL_VALUE_RANGES['h_prism_base'] : RXSS_TOOL_VALUE_RANGES['v_prism_base'];

	const basePropertyName = `${leftPrism ? 'left_' : 'right_'}prism_${horizontalPrism ? 'horizontal_' : 'vertical_'}base`;

	const { strength } = decipherPrism(getValues(activeInput.id as string), activeInput.id as keyof RxssPomsPayload) ?? {};

	return (
		<>
			{/* Prism Strength */}
			<WheelPicker.Arm
				values={RXSS_TOOL_VALUE_RANGES['prism_strength'].map(({ copy }) => copy)}
				defaultValue={RXSS_TOOL_VALUE_RANGES['prism_strength'].find(({ value }) => value === strength)?.copy}
				onChange={newValue => {
					setValue(activeInput.id as string, newValue.split(' △')[0], { shouldDirty: true, shouldValidate: true });
					// Use default base direction if the user hasn't interacted with that arm yet
					if (!getValues(basePropertyName))
						setValue(basePropertyName, baseValues[0].value, { shouldDirty: true, shouldValidate: true });
				}}
				instruction='Make a Selection'
				textAlign='right'
				dataTags={{ 'data-wheel-picker-values': activeInput.id }}
			/>
			{/* Prism Base Direction */}
			<WheelPicker.Arm
				values={baseValues.map(({ copy }) => copy)}
				defaultValue={getValues(basePropertyName) || baseValues[0]}
				onChange={newValue => {
					baseValues.forEach(({ value, copy }) => {
						if (copy === newValue) {
							setValue(basePropertyName, value, { shouldDirty: true, shouldValidate: true });
						}
					});
				}}
				textAlign='left'
				dataTags={{ 'data-wheel-picker-values': basePropertyName }}
			/>
		</>
	);
};

export default WheelPickerArms;
