import Icon from '../../icons/Icon';

const TriangleFace = () => {
	return (
		<Icon label='Triangle Face'>
			<svg width='30' height='31' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M2.51012 20.3194C0.599368 24.9287 3.9869 30 8.97652 30H21.1438C26.1054 30 29.4921 24.9809 27.635 20.3799L21.6324 5.50881C19.2871 -0.301718 11.0744 -0.340096 8.67488 5.44826L2.51012 20.3194Z'
					stroke='black'
					strokeWidth='2'
				/>
			</svg>
		</Icon>
	);
};

export default TriangleFace;
