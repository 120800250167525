import { CustomerCreateInput, Mutation } from '@ts/shopify-storefront-api';
import { PairError } from '..';
import fetchStorefrontApi from '../fetch-storefront-api';
import customerCreateMutation from '../mutations/customer-create';
import throwUserErrors from '../throw-user-errors';

/**
 *
 * Creates a customer in Shopify and sends an email confirming creation of customer.
 *
 */
const createCustomer = async ({ firstName, lastName, email, password }: CustomerCreateInput) => {
	if (!(firstName && lastName && email && password))
		throw new PairError({ message: 'All fields are required to create an account' });

	const mutation: Mutation = await fetchStorefrontApi(customerCreateMutation, {
		variables: { input: { firstName, lastName, email, password } },
	});

	const { customerCreate } = mutation;

	throwUserErrors(customerCreate?.customerUserErrors);

	return null;
};

export default createCustomer;
