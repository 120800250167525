import Link from 'next/link';
import { REFER_WIDGET_COPY } from '@constants';
import { Button, Container, CutPaper, Heading, Img, Paragraph, TypographyButton } from '@components';
import createCutPaperPath from '@utils/create-cut-paper';
import variables from '@styles/export.module.scss';
import styles from './ReferWidget.module.scss';

const ReferWidget = () => {
	const path = createCutPaperPath('papaya');
	return (
		<Container className={styles.container}>
			<Link href={REFER_WIDGET_COPY.CTA_LINK} role='none' data-handle='all-tops-widget'>
				<CutPaper padding='0.5rem' cutPaperPath={path} backgroundColor={variables.blue2} textColor='white'>
					<TypographyButton small>{REFER_WIDGET_COPY.TITLE}</TypographyButton>
				</CutPaper>
				<Heading tag='h4' className={styles.heading}>
					{REFER_WIDGET_COPY.HEADING}
				</Heading>
				<Paragraph style={{ textAlign: 'center', padding: '1.2rem 0' }}>{REFER_WIDGET_COPY.BODY}</Paragraph>
				<Button color='white'>{REFER_WIDGET_COPY.CTA_TEXT}</Button>
				<Img src={REFER_WIDGET_COPY.IMAGE_URL} alt='Refer a Friend' aspectRatio={'4/3'} height={455} width={254} />
			</Link>
		</Container>
	);
};

export default ReferWidget;
