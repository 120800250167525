import { useCustomer } from '@services/shopify';
import { usePortalToken } from '@services/prive';
import { NEXT_APP_ENV } from '@constants';
import styles from './Subscriptions.module.scss';

const Subscriptions = () => {
	const customer = useCustomer();
	const { data: portalToken } = usePortalToken({ subscriberId: customer.data?.subscriberId });
	const isProd = process.env.NEXT_PUBLIC_APP_ENV === NEXT_APP_ENV.PROD;
	const storeUrl = isProd ? 'pair-eyewear.myshopify.com' : 'paireyewear-staging.myshopify.com';

	const url = `https://subscriptions.tryprive.com/?storeUrl=${storeUrl}&access_token=${portalToken}`;

	if (!portalToken) return null;

	return (
		<div className={styles.container}>
			<iframe src={url} className={styles.iframe} allowFullScreen></iframe>
		</div>
	);
};

export default Subscriptions;
