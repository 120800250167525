import { useQuery } from '@tanstack/react-query';
import { getPortalToken } from '../operations';

type usePortalTokenProps = { subscriberId: string };

const usePortalToken = ({ subscriberId }: usePortalTokenProps) => {
	return useQuery(
		['subscriber', subscriberId],
		async () => {
			const response = await getPortalToken(subscriberId);
			return response;
		},
		{ enabled: !!subscriberId, refetchOnMount: false, refetchOnWindowFocus: false }
	);
};

export default usePortalToken;
