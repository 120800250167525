const LightResponsive = ({ className }) => {
	return (
		<svg viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M25.387 16.6114C23.8907 16.4507 22.5664 17.5745 22.4871 19.0721L22.1737 24.9919C22.1104 26.1872 21.4406 27.2676 20.3965 27.8586L15.1829 30.8099C13.8656 31.5556 13.4547 33.2555 14.2865 34.5183L17.4803 39.3667C18.149 40.3819 18.2443 41.6691 17.7323 42.7705L15.2534 48.1024C14.6093 49.4876 15.2886 51.1266 16.7262 51.6558L22.155 53.6544C23.3081 54.0789 24.1563 55.0715 24.3931 56.2737L25.5078 61.9331C25.803 63.4317 27.3184 64.3612 28.7933 63.9483L34.4703 62.3592C35.643 62.0309 36.903 62.3298 37.8022 63.1495L42.0962 67.0642C43.2146 68.0838 44.9641 67.9505 45.9127 66.7734L49.6672 62.1144C50.4191 61.1814 51.5962 60.6946 52.7903 60.8229L58.7047 61.4579C60.2009 61.6186 61.5252 60.4948 61.6045 58.9971L61.9179 53.0773C61.9812 51.8821 62.651 50.8016 63.6951 50.2106L68.9088 47.2593C70.2261 46.5136 70.637 44.8137 69.8051 43.5509L66.6113 38.7026C65.9426 37.6874 65.8473 36.4002 66.3594 35.2988L68.8383 29.9669C69.4823 28.5816 68.803 26.9427 67.3654 26.4135L61.9367 24.4149C60.7835 23.9903 59.9354 22.9977 59.6986 21.7956L58.5838 16.1362C58.2886 14.6376 56.7733 13.7081 55.2983 14.121L49.6213 15.7101C48.4486 16.0383 47.1886 15.7394 46.2895 14.9197L41.9954 11.005C40.8771 9.98542 39.1276 10.1188 38.1789 11.2959L34.4244 15.9549C33.6726 16.8878 32.4955 17.3746 31.3013 17.2464L25.387 16.6114ZM53.192 55.8288C62.621 49.5082 65.2171 36.8545 58.9906 27.5659C52.7642 18.2774 40.0729 15.8715 30.6439 22.1921C21.215 28.5127 18.6188 41.1664 24.8453 50.4549C31.0718 59.7435 43.763 62.1494 53.192 55.8288Z'
				fill='#FF510E'
			/>
			<path
				d='M57.4401 28.7432C63.1404 37.2469 60.8679 48.7615 52.3643 54.4619C43.8606 60.1622 32.346 57.8897 26.6456 49.386C20.9453 40.8823 23.2178 29.3677 31.7215 23.6674C40.2251 17.967 51.7397 20.2396 57.4401 28.7432Z'
				fill='#FF510E'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M46.4219 30.8457C44.9197 31.4721 44.2098 33.1976 44.8362 34.6998L43.023 35.4559C41.979 32.9523 43.1622 30.0764 45.6658 29.0324C48.1694 27.9885 51.0452 29.1717 52.0892 31.6753L50.276 32.4314C49.6496 30.9292 47.924 30.2193 46.4219 30.8457ZM32.8225 36.5167C31.3203 37.1431 30.6104 38.8686 31.2368 40.3708L29.4235 41.1269C28.3795 38.6233 29.5628 35.7474 32.0663 34.7034C34.5699 33.6594 37.4458 34.8427 38.4898 37.3462L36.6766 38.1024C36.0502 36.6002 34.3246 35.8903 32.8225 36.5167Z'
				fill='#CB3800'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M47.29 50.4378C41.1816 53.2795 33.9261 50.6313 31.0845 44.5229L32.8657 43.6943C35.2498 48.8189 41.3367 51.0406 46.4613 48.6565C51.1689 46.4665 53.4905 41.0984 51.8622 36.1682L53.7277 35.5521C55.6685 41.4287 52.9012 47.8274 47.29 50.4378Z'
				fill='#CB3800'
			/>
			<path
				d='M52.8715 9.52272C52.6957 10.5039 51.7578 11.1568 50.7766 10.9811C49.7954 10.8053 49.1425 9.86744 49.3183 8.88625L49.8752 5.7772C50.0509 4.79601 50.9888 4.14307 51.97 4.31883C52.9512 4.49458 53.6041 5.43247 53.4284 6.41366L52.8715 9.52272Z'
				fill='#B5DEF4'
			/>
			<path
				d='M15.1371 19.8963C15.9467 20.4778 16.1316 21.6055 15.5501 22.4151C14.9686 23.2247 13.8408 23.4096 13.0312 22.8281L10.4659 20.9855C9.65628 20.404 9.47138 19.2762 10.0529 18.4666C10.6344 17.657 11.7622 17.4721 12.5718 18.0536L15.1371 19.8963Z'
				fill='#B5DEF4'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M53.3631 53.744C53.0403 53.9923 52.7073 54.2318 52.3642 54.4618C50.2864 55.8547 48.0288 56.7715 45.7202 57.2378C46.9251 54.3847 48.7415 51.792 51.0758 49.6723C51.6799 49.1237 52.6205 49.5659 52.711 50.3769C52.8364 51.5009 53.0523 52.6264 53.3631 53.744Z'
				fill='black'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M58.09 61.3919C56.664 59.4331 55.5471 57.1982 54.8314 54.7377C54.8215 54.7037 54.8117 54.6697 54.802 54.6357C54.2899 55.0545 53.7532 55.4529 53.1923 55.8289C50.608 57.5612 47.7786 58.6381 44.9045 59.0973C44.0436 61.84 43.725 64.7762 44.0371 67.7448C44.7439 67.6994 45.4315 67.3708 45.913 66.7734L49.6675 62.1145C50.4193 61.1815 51.5964 60.6947 52.7906 60.8229L58.09 61.3919Z'
				fill='black'
			/>
			<path
				d='M89.7328 69.8869C89.8595 69.0807 88.9735 68.5374 88.246 68.9072C87.081 69.4994 85.8453 69.9933 84.5461 70.3755C73.1496 73.728 61.1931 67.2071 57.8406 55.8105C57.3023 53.9809 57.0186 52.1368 56.9687 50.3161C56.9463 49.5004 56.0459 48.9811 55.398 49.4774C48.5392 54.7306 45.2575 63.8474 47.8415 72.6315C51.194 84.0281 63.1505 90.549 74.5471 87.1965C82.7898 84.7717 88.482 77.8462 89.7328 69.8869Z'
				fill='#B5DEF4'
			/>
			<path
				d='M74.2765 47.6327L74.4755 47.8786C76.791 50.7404 79.8753 52.8823 83.3658 54.0523C80.7125 56.6006 78.8428 59.854 77.9766 63.4296L77.9022 63.7368L77.7032 63.4909C75.3878 60.6291 72.3034 58.4872 68.8129 57.3172C71.4662 54.7689 73.3359 51.5156 74.2021 47.9399L74.2765 47.6327Z'
				fill='#FF510E'
			/>
			<path
				d='M42.5717 75.312L42.6287 75.5525C43.2917 78.3518 44.74 80.9041 46.8031 82.909C44.0719 83.8067 41.6542 85.466 39.8345 87.6918L39.6781 87.8831L39.6211 87.6425C38.9581 84.8432 37.5099 82.291 35.4468 80.286C38.178 79.3884 40.5956 77.7291 42.4153 75.5033L42.5717 75.312Z'
				fill='#FF510E'
			/>
			<path
				d='M78.4222 81.9758L78.5718 82.1469C79.7438 83.4871 81.2725 84.4671 82.9797 84.9725C81.7969 86.3017 81.0164 87.9397 80.7294 89.6958L80.6928 89.9199L80.5432 89.7488C79.3712 88.4086 77.8425 87.4286 76.1353 86.9232C77.3181 85.5941 78.0986 83.956 78.3856 82.1999L78.4222 81.9758Z'
				fill='#FF510E'
			/>
			<path
				d='M15.9067 64.1136C15.2514 64.8647 14.1113 64.9424 13.3602 64.2871C12.609 63.6318 12.5313 62.4917 13.1866 61.7405L15.263 59.3604C15.9183 58.6093 17.0585 58.5316 17.8096 59.1869C18.5607 59.8422 18.6384 60.9823 17.9831 61.7335L15.9067 64.1136Z'
				fill='#B5DEF4'
			/>
			<path
				d='M73.1715 39.1078C72.1801 39.2117 71.2922 38.4922 71.1883 37.5009C71.0844 36.5095 71.8038 35.6216 72.7952 35.5177L75.9365 35.1884C76.9279 35.0845 77.8158 35.8039 77.9197 36.7953C78.0236 37.7867 77.3042 38.6746 76.3128 38.7785L73.1715 39.1078Z'
				fill='#B5DEF4'
			/>
		</svg>
	);
};

export default LightResponsive;
