import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { PRODUCT_TYPES } from '@constants';
import { Body, Button, Flex, Heading, HeartEmpty, HeartSparkle, VerticalCard } from '@components';
import { useFavorites } from '@services/poms/hooks';
import { getShopifyIdFromGID, sortFavoritesByCollection } from '@utils/shopify';
import { normalizePomsFavorites } from '@utils/poms';
import styles from './MyFavorites.module.scss';

type MyFavoritesProps = {
	firstName: string;
	customerId: string;
};

const MyFavorites = ({ customerId }: MyFavoritesProps) => {
	const { locale } = useRouter();
	const [sortedFavorites, setSortedFavorites] = useState([]);
	const validCustomerId = getShopifyIdFromGID(customerId);
	const { data: favorites, isLoading: isFavoritesLoading } = useFavorites(validCustomerId);

	useEffect(() => {
		if (isFavoritesLoading) {
			return;
		}
		setSortedFavorites(sortFavoritesByCollection(favorites));
	}, [favorites, isFavoritesLoading]);

	const content = (
		<section>
			<Flex pad={4} gap={4} align='center'>
				<HeartSparkle />
				<Heading style={{ fontSize: '2.4rem', margin: '0' }} tag='h4'>
					My Favorites
				</Heading>
			</Flex>
			{sortedFavorites.map((fav, index) => {
				if (!fav.variant || !fav.available) {
					return null;
				}

				const parsedFav = normalizePomsFavorites({ locale, fav });

				return (
					<VerticalCard
						key={`${parsedFav.id}-${index}`}
						product={parsedFav.product}
						variant={parsedFav.product.variants[0]}
						secondaryAction='favorite'
						aspectRatio={'4/3'}
						showCollectionLozenge={true}
						primaryAction={parsedFav.product.type.includes(PRODUCT_TYPES.BASE_FRAME) ? 'view' : 'cart'}
					/>
				);
			})}
		</section>
	);

	const emptyState = (
		<section className={styles.empty}>
			<HeartEmpty label='empty heart' />
			<Heading tag='h5'>You currently have no Favorites</Heading>
			<Body>Start exploring and save your favorite top frames now!</Body>
			<Button href='/top-frames'>Explore All Tops</Button>
		</section>
	);

	return <div className={styles.myFavorites}>{!isFavoritesLoading && !!sortedFavorites.length ? content : emptyState}</div>;
};

export default MyFavorites;
