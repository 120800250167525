import { Body, Heading } from '@components';
import Img from '../Img';
import styles from './ContentCard.module.scss';

type ContentCardProps = {
	title: string;
	description: string;
	alt?: string;
	className?: string;
	horizontal?: boolean;
	image?:
		| {
				src: string;
				height: number;
				width: number;
		  }
		| string;
};

const ContentCard = ({ title, description, image, alt, className = '', horizontal = false }: ContentCardProps) => {
	return (
		<div className={`${styles.container} ${className} ${horizontal ? styles.horizontal : ''}`}>
			<div className={styles.contentCardTextContainer}>
				<Heading tag='h5'>{title}</Heading>
				<Body>{description}</Body>
			</div>
			{image && typeof image === 'object' && (
				<div className={styles.contentCardImgContainer}>
					<Img className={styles.contentCardImg} src={image.src} noSrcset alt={alt} />
				</div>
			)}
		</div>
	);
};

export default ContentCard;
