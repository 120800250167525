import Icon from '../../icons/Icon';

const OvalFace = () => {
	return (
		<Icon label='Oval Face'>
			<svg width='26' height='32' viewBox='0 0 26 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M1.49559 13.6194C0.758864 6.88366 6.03409 1 12.81 1C19.7089 1 25.0216 7.08841 24.0871 13.9237L22.9788 22.0301C22.2761 27.1693 17.8861 31 12.699 31C7.40547 31 2.96072 27.0149 2.38517 21.7527L1.49559 13.6194Z'
					stroke='black'
					strokeWidth='2'
				/>
			</svg>
		</Icon>
	);
};

export default OvalFace;
