import { Flex, Heading, Img, Paragraph, Title } from '@components';
import styles from './PdContent.module.scss';

type PdContentProps = {
	data: {
		headerBackgroundColor: string;
		headerImage: {
			title: string;
			assetUrl: string;
		};
		heading: string;
		subheading: string;
		paragraph: string;
		steps: {
			heading: string;
			paragraph: string;
		}[];
		buttonText: string;
	};
};

const PdContent = ({ data }: PdContentProps) => {
	return (
		<>
			<div className={styles.hero} style={{ backgroundColor: data.headerBackgroundColor }}>
				<Img src={data.headerImage.assetUrl} alt={data.headerImage.title} width={260} height={260} noSrcset />
			</div>
			<div className={styles.content}>
				<div className='container'>
					<Heading tag='h3'>{data.heading}</Heading>
					<Heading tag='h6'>{data.subheading}</Heading>
					<Paragraph>{data.paragraph}</Paragraph>
				</div>
				<div className={styles.instructions}>
					{data.steps.map((step, index) => {
						return (
							<Flex gap={5} key={step.heading}>
								<div className={styles.step}>
									<Title className={styles.number}>{index + 1}</Title>
									{index !== data.steps.length - 1 && <span className={styles.border} />}
								</div>
								<div className={styles.description}>
									<Heading tag='h5'>{step.heading}</Heading>
									<Paragraph>{step.paragraph}</Paragraph>
								</div>
							</Flex>
						);
					})}
				</div>
			</div>
		</>
	);
};

export default PdContent;
