import { CustomerResetInput, Mutation } from '@ts/shopify-storefront-api';
import { PairError } from '..';
import fetchStorefrontApi from '../fetch-storefront-api';
import customerResetPasswordMutation from '../mutations/customer-reset-password';
import throwUserErrors from '../throw-user-errors';

const resetPassword = async (id: string, input: CustomerResetInput) => {
	if (!(id && input.resetToken && input.password))
		throw new PairError({ message: 'An id, token, and password are required to reset' });
	const mutation: Mutation = await fetchStorefrontApi(
		customerResetPasswordMutation,
		{
			variables: {
				id,
				input,
			},
		},
		null
	);
	const { customerReset } = mutation;

	throwUserErrors(customerReset?.customerUserErrors);

	return null;
};

export default resetPassword;
