import Parser from 'html-react-parser';
import cn from 'classnames';
import { convertSchemaToHtml } from '@thebeyondgroup/shopify-rich-text-renderer';
import { Flex, Img, Paragraph } from '@components';
import { Image as ImageProps } from '@ts/shopify-storefront-api';
import { useIsMobile } from '@utils/hooks';
import { MOBILE_MAX_WIDTH } from '@constants';
import { useWindowDimensions } from '@utils/hooks';

import styles from './LicensingInfoArea.module.scss';

export interface LicensingDataProps {
	collectionLicenseTagline?: string;
	collectionLicenseDescription?: string;
	collectionLicenseLogo?: ImageProps;
	productLicenseInfo?: { [k: string]: string };
	licensingImage: ImageProps;
	/** See the docs for more info:
	 * @link https://shopify.dev/docs/apps/custom-data/metafields/types#rich-text-formatting */
	licensingImageCaption: string;
	/** See the docs for more info:
	 * @link https://shopify.dev/docs/apps/custom-data/metafields/types#rich-text-formatting */
	licensingProductDescription: string;
	productLicensingLogo?: ImageProps;
}

const LicensingInfoArea = ({
	collectionLicenseDescription,
	collectionLicenseLogo,
	licensingImageCaption,
	licensingImage,
	licensingProductDescription,
	productLicensingLogo,
}: LicensingDataProps) => {
	const { width } = useWindowDimensions();
	const isMobile = useIsMobile({ maxWidth: MOBILE_MAX_WIDTH });
	const isDesktopView = width > 991;

	let licenseDescription = <Paragraph>{collectionLicenseDescription}</Paragraph>;
	const hasLicensingData = licensingImage || licensingImageCaption || licensingProductDescription;

	const logo = productLicensingLogo ?? collectionLicenseLogo;

	if (hasLicensingData) {
		const productLicenseCaption = licensingImageCaption && Parser(convertSchemaToHtml(JSON.parse(licensingImageCaption)));
		const productLicenseDescription =
			licensingProductDescription && Parser(convertSchemaToHtml(JSON.parse(licensingProductDescription)));

		licenseDescription = (
			<Flex gap={3} column={!isDesktopView}>
				{(productLicenseCaption || licensingImage) && (
					<Flex className={cn(styles.licensing, styles.imageContainer)}>
						{licensingImage && <img src={licensingImage.url} alt={licensingImage.altText} />}
						{productLicenseCaption && (
							<div className={'licensing__caption paragraph'} style={{ paddingLeft: !isMobile && '1.6rem' }}>
								{productLicenseCaption}
							</div>
						)}
					</Flex>
				)}
				<Flex className={styles.licensing}>
					{productLicenseDescription && <div className={'licensing__description '}>{productLicenseDescription}</div>}
				</Flex>
			</Flex>
		);
	}

	return (
		<Flex column>
			<div className={styles.logoContainer}>
				{logo && (
					<Img className={styles.logo} src={logo.url} alt={logo.altText} width={logo.width} height={logo.height} />
				)}
			</div>
			{licenseDescription}
		</Flex>
	);
};

export default LicensingInfoArea;
