import React, { forwardRef } from 'react';
import Button from '../Button';
import styles from './RectangleMatrix.module.scss';

type RectangleMatrixProps = {
	xlength: number;
	ylength: number;
	onClick?: () => void;
};

const RectangleMatrix = forwardRef<HTMLButtonElement, RectangleMatrixProps>(
	({ xlength, ylength, onClick, ...rest }: RectangleMatrixProps, ref) => {
		const mappedRectangles = new Array(xlength * ylength)
			.fill(true)
			.map((__, i) => <div key={i} className={styles.rectangle}></div>);

		return (
			<Button
				onClick={onClick}
				extraClasses={styles.button}
				color='white'
				style={{ gridTemplateColumns: `repeat(${xlength}, auto)`, gridTemplateRows: `repeat(${ylength}, auto)` }}
				ref={ref}
				{...rest}
			>
				{mappedRectangles}
			</Button>
		);
	}
);

RectangleMatrix.displayName = 'RectangleMatrix';

export default RectangleMatrix;
