/* eslint-disable @next/next/no-img-element */
import cn from 'classnames';
import { Body, CutPaper, Flex, Heading, Title } from '@components';
import { generateImgSrc } from '@utils/images';
import { NormalizedCollection, NormalizedBuildFlowCollection } from '@ts/product';
import styles from '../CollectionStep/CollectionStep.module.scss';

type CollectionBannerProps = {
	collection: NormalizedCollection | NormalizedBuildFlowCollection;
	cutPaperPath?: string;
	extraClasses?: string;
	title?: string;
	description?: string;
};

function CollectionBanner({ collection, cutPaperPath, extraClasses, title, description }: CollectionBannerProps) {
	return (
		<Flex align='center' justify='center' className={cn(styles['group-header'], extraClasses)}>
			<img
				src={generateImgSrc(collection.image.url, [767])}
				alt={`${collection.title} Banner`}
				width={collection.image.width}
				height={collection.image.height}
				className={styles['banner-image']}
			/>
			{cutPaperPath && (
				<CutPaper cutPaperPath={cutPaperPath} backgroundColor='white' padding={'4px 10px 6px'}>
					<Title>{`${collection.title} Collection`}</Title>
				</CutPaper>
			)}
			{!cutPaperPath && (
				<>
					<div className={styles['copy']}>
						<Heading tag='h5' className={styles['title']}>
							{title || collection.title}
						</Heading>
						<Body className={styles['description']}>{description}</Body>
					</div>
					<div className={cn(styles.gradientOverlay)}></div>
				</>
			)}
		</Flex>
	);
}

export default CollectionBanner;
