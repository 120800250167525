// eslint-disable-next-line import/no-extraneous-dependencies
import { Text } from '@contentful/rich-text-types';
import { Heading, InteractiveCard } from '@components';
import { useContentfulAsset } from '@services/contentful/hooks';
import { PRODUCT_TYPES, ABOUT_VALUE_PROPOSITIONS } from '@utils/constants';
import { VisualAssetEntrySkeletonType } from '@ts/contentful';

import styles from './AboutValueProp.module.scss';

type AboutValuePropProps = {
	heading: string;
	type: (typeof PRODUCT_TYPES)[keyof typeof PRODUCT_TYPES] | 'METAL_TEMPLE';
};

const AboutValueProp = ({ heading, type }: AboutValuePropProps) => {
	const ContentfulAssets = useContentfulAsset<VisualAssetEntrySkeletonType>({
		id: ABOUT_VALUE_PROPOSITIONS[type]?.map(val => val.id).join('+'),
		query: {
			'content_type': 'visualAsset',
			'fields.slug[in]': ABOUT_VALUE_PROPOSITIONS[type]?.map(val => val.cmsSlug).join(','),
		},
	});

	return (
		ContentfulAssets?.data && (
			<div className={styles.container}>
				<Heading tag='h4' className={styles.heading}>
					{heading}
				</Heading>
				<div className={styles.cardGrid}>
					{ContentfulAssets.data?.map(({ fields }, index) => {
						const { title, media, description } = fields;
						return (
							<div data-value-prop={`${type}-${index + 1}`} key={index}>
								<InteractiveCard
									background={media.fields.file.url as string}
									title={title}
									text={
										!!description?.content ? (description?.content?.[0]?.content?.[0] as Text)?.value : null
									}
									className={styles.valueProp}
									videoUrl={fields.videoUrl}
								/>
							</div>
						);
					})}
				</div>
			</div>
		)
	);
};

export default AboutValueProp;
