const SingleVision = ({ className }) => {
	return (
		<svg viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
			<g clipPath='url(#clip0_15054_5499)'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M40.1183 20.1446C26.1029 23.7578 13.1114 28.1746 9.95075 37.8666C8.31834 42.8737 10.1302 50.7171 12.8498 56.781C18.6387 69.6884 26.3841 76.7674 40.1913 76.4765C45.1262 76.3736 51.687 75.4142 57.161 73.3274C61.0163 71.8581 64.4268 70.7476 67.4386 68.9681C76.4559 63.643 79.3302 54.507 80.4745 44.7975C81.3364 37.486 80.6818 26.7479 74.7866 21.3142C68.7703 15.7682 58.5198 16.9155 48.2297 18.3965L40.1183 20.1446Z'
					fill='#B5DEF4'
				/>
				<g opacity='0.4'>
					<path
						opacity='0.6'
						fillRule='evenodd'
						clipRule='evenodd'
						d='M79.7193 49.3427L48.4722 18.4316C50.0629 18.2075 51.6502 17.9951 53.219 17.8176L80.3563 44.663C80.3308 44.9268 80.3033 45.1867 80.2739 45.4425C80.1235 46.7556 79.9421 48.0582 79.7193 49.3427Z'
						fill='white'
					/>
					<path
						opacity='0.6'
						fillRule='evenodd'
						clipRule='evenodd'
						d='M79.2318 48.9638L47.9846 18.0527C49.5753 17.8286 51.1627 17.6161 52.7314 17.4387L79.8687 44.2842C79.8432 44.5479 79.8157 44.8078 79.7864 45.0636C79.636 46.3767 79.4546 47.6793 79.2318 48.9638Z'
						fill='white'
					/>
					<path
						opacity='0.8'
						fillRule='evenodd'
						clipRule='evenodd'
						d='M70.5296 67.5951L26.6389 24.1766C30.6433 22.701 35.0414 21.457 39.5448 20.2963L39.6573 20.2721L77.459 57.6672C75.9554 61.442 73.7655 64.8401 70.5296 67.5951Z'
						fill='white'
					/>
				</g>
				<path
					d='M85.8297 55.8404L85.708 55.9104C84.2913 56.7244 82.6716 57.1172 81.0394 57.0428C81.7298 58.5225 81.9829 60.1685 81.769 61.7874L81.7506 61.9265L81.8724 61.8565C83.2891 61.0426 84.9088 60.6497 86.541 60.7242C85.8506 59.2445 85.5975 57.5984 85.8114 55.9796L85.8297 55.8404Z'
					fill='#AAE0FE'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M62.302 17.1905L59.3996 19.0148C58.4644 19.6026 58.1828 20.8372 58.7706 21.7724L73.3292 44.9348C73.917 45.87 75.1516 46.1516 76.0868 45.5638L80.672 42.6818C81.1696 35.5399 80.1348 26.244 74.7863 21.3143C71.6032 18.38 67.2346 17.3193 62.302 17.1905Z'
					fill='black'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M70.3715 10.0545C71.9567 9.05814 72.434 6.96544 71.4377 5.38029C70.4413 3.79514 68.3486 3.31781 66.7635 4.31415C65.1783 5.31048 64.701 7.40318 65.6973 8.98833C66.6937 10.5735 68.7864 11.0508 70.3715 10.0545ZM69.4695 8.61906C70.2621 8.1209 70.5008 7.07455 70.0026 6.28197C69.5044 5.4894 68.4581 5.25074 67.6655 5.7489C66.8729 6.24707 66.6343 7.29342 67.1324 8.08599C67.6306 8.87857 68.677 9.11723 69.4695 8.61906Z'
					fill='#FF7E4D'
				/>
				<path
					d='M59.7706 17.0781C59.1828 16.1429 59.4644 14.9083 60.3996 14.3205L77.8217 3.36994C78.7569 2.78214 79.9915 3.06375 80.5793 3.99893L95.1378 27.1613C95.7256 28.0965 95.444 29.3311 94.5088 29.9189L77.0868 40.8695C76.1516 41.4573 74.917 41.1757 74.3292 40.2405L59.7706 17.0781Z'
					fill='#FF510E'
				/>
				<path
					d='M63.8084 18.7245C62.9267 17.3217 63.3491 15.4698 64.7519 14.5881L76.1754 7.40793C77.5782 6.52623 79.4301 6.94864 80.3118 8.35141L89.0885 22.3149L88.0223 26.9891L86.9562 31.6633L78.733 36.8319C77.3302 37.7136 75.4783 37.2912 74.5966 35.8884L63.8084 18.7245Z'
					fill='white'
				/>
				<path d='M83.3481 25.9224L89.0885 22.3143L86.9562 31.6627L83.3481 25.9224Z' fill='#B5DEF4' />
				<path d='M62.1096 11.2437L74.3078 3.57658L77.0138 7.88182L64.8156 15.5489L62.1096 11.2437Z' fill='#FF7E4D' />
				<path d='M77.0139 7.88131L63.0117 12.6782L64.8157 15.5484L77.0139 7.88131Z' fill='#CB3800' />
				<path
					d='M75.3336 21.9507L72.2829 20.2645L71.5069 20.7522L73.1305 23.3354L72.1137 23.9745L68.0547 17.5166L70.1953 16.1711C70.671 15.8721 71.1269 15.7066 71.5629 15.6745C72.0049 15.6386 72.3977 15.7158 72.7412 15.9061C73.0907 16.0926 73.3784 16.3655 73.6042 16.7248C73.869 17.146 73.9915 17.6048 73.9716 18.1012C73.9539 18.5877 73.7402 19.0461 73.3305 19.4764L76.5466 21.1882L75.3336 21.9507ZM70.9754 19.9067L72.0993 19.2003C72.4798 18.9611 72.7029 18.6826 72.7686 18.3648C72.8402 18.0432 72.7709 17.7152 72.5607 17.3807C72.3504 17.0462 72.0917 16.8458 71.7847 16.7795C71.4737 16.7071 71.1249 16.7924 70.7384 17.0353L69.6146 17.7417L70.9754 19.9067Z'
					fill='#AAD6EF'
				/>
				<path
					d='M78.2247 16.5169L81.4418 18.1114L80.2912 18.8346L78.1376 17.7641L78.2308 20.1296L77.1695 20.7967L77.1743 17.2807L73.9514 15.6769L75.102 14.9537L77.2555 16.0242L77.1623 13.6587L78.2237 12.9916L78.2247 16.5169Z'
					fill='#AAD6EF'
				/>
				<path d='M73.0159 25.4097L83.0614 19.0956L83.9634 20.5307L73.9179 26.8447L73.0159 25.4097Z' fill='#EFEDEE' />
				<path d='M74.8196 28.2803L84.8651 21.9662L85.7672 23.4013L75.7216 29.7154L74.8196 28.2803Z' fill='#EFEDEE' />
				<path d='M76.6238 31.1504L78.7764 29.7974L79.6784 31.2325L77.5258 32.5855L76.6238 31.1504Z' fill='#EFEDEE' />
				<path
					d='M74 86C74 87.1046 62.1355 88 47.5 88C32.8645 88 21 87.1046 21 86C21 84.8954 32.8645 84 47.5 84C62.1355 84 74 84.8954 74 86Z'
					fill='#EFEDEE'
				/>
				<path
					d='M86.0644 65.0285L85.8366 65.1594C83.1859 66.6825 80.1552 67.4175 77.1012 67.2782C78.393 70.0468 78.8666 73.1268 78.4664 76.1558L78.432 76.4161L78.6598 76.2852C81.3106 74.7621 84.3412 74.0271 87.3953 74.1664C86.1034 71.3978 85.6298 68.3178 86.03 65.2888L86.0644 65.0285Z'
					fill='#FF510E'
				/>
				<path
					d='M50.5267 5.18147L50.5432 5.35659C50.7362 7.39446 50.3436 9.4453 49.4116 11.2679C51.4572 11.2485 53.4655 11.8142 55.2005 12.8982L55.3496 12.9913L55.333 12.8162C55.14 10.7783 55.5327 8.72751 56.4647 6.90494C54.4191 6.92427 52.4107 6.35865 50.6758 5.27462L50.5267 5.18147Z'
					fill='#FF510E'
				/>
			</g>
			<defs>
				<clipPath id='clip0_15054_5499'>
					<rect width='96' height='96' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};

export default SingleVision;
