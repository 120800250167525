import { Fragment, memo } from 'react';
import {
	Accordian,
	DiamondFace,
	Flex,
	HeartFace,
	OblongFace,
	OvalFace,
	Paragraph,
	RoundFace,
	SquareFace,
	TriangleFace,
} from '@components';
import { FrameSizingData } from '@ts/product';
import { FACE_SHAPES, IDEAL_FOR_FACE_SHAPES } from '@constants';

import styles from './FitDetails.module.scss';

const FACE_SHAPE_ICONS = {
	[FACE_SHAPES.DIAMOND]: DiamondFace,
	[FACE_SHAPES.HEART]: HeartFace,
	[FACE_SHAPES.OBLONG]: OblongFace,
	[FACE_SHAPES.OVAL]: OvalFace,
	[FACE_SHAPES.ROUND]: RoundFace,
	[FACE_SHAPES.SQUARE]: SquareFace,
	[FACE_SHAPES.TRIANGLE]: TriangleFace,
};

const FitDetails = ({
	name,
	description,
	tags,
	handle,
	frameSizings,
	isOpen = false,
	isMixedMaterial = false,
	onClick,
}: {
	name: string;
	description: string;
	handle: string;
	tags: string[];
	frameSizings: FrameSizingData;
	isOpen?: boolean;
	isMixedMaterial?: boolean;
	onClick: () => void;
}) => {
	const { frame, lens, units } = frameSizings;

	return (
		<Accordian title='Details' isOpen={isOpen} onClick={onClick}>
			<Flex className={styles.content}>
				<Flex column className={styles.container}>
					<Paragraph className={styles.title}>{`About ${name}:`}</Paragraph>
					<Paragraph>{description}</Paragraph>
				</Flex>
				<Flex column className={styles.container}>
					<Paragraph className={styles.title}>Fit & Material:</Paragraph>
					<Paragraph>
						{isMixedMaterial
							? 'This frame is made of hand-polished, flexible, and durable acetate with metal temples'
							: 'Our frames are made of hand-polished, flexible, and durable acetate'}
						. {name} measures {lens.width}-{frame.bridge}-{frame.temple} with a lens height of {lens.height}
						{units} and a head width of {frame.width}
						{units}.
					</Paragraph>
				</Flex>
				<Flex column className={styles.container}>
					<Paragraph className={styles.title}>Ideal for:</Paragraph>
					<Paragraph>{IDEAL_FOR_FACE_SHAPES[handle]}</Paragraph>
					<Flex className={styles.faceShape}>
						{tags?.map(tag => {
							return FACE_SHAPE_ICONS[tag] ? <Fragment key={tag}>{FACE_SHAPE_ICONS[tag]()}</Fragment> : null;
						})}
					</Flex>
				</Flex>
			</Flex>
		</Accordian>
	);
};

export default memo(FitDetails);
