import { useRouter } from 'next/router';
import { TopFrameFiltering } from '@components';
import { CUSTOMER_JOURNEYS, LOCALE_DICT } from '@constants';
import { COLLECTION_LISTS } from '@constants/contentful';
import { useBFContext } from '@context';
import { ALGOLIA_REFINEMENTS_BUILDFLOW, ALGOLIA_SEARCH_INDEXES } from '@utils/algolia';

const CollectionStep__BFFiltering = () => {
	const { frame, journey } = useBFContext();
	const { locale } = useRouter();
	const countryCode = LOCALE_DICT[locale].countryCode;

	return (
		<TopFrameFiltering
			type='bf-all-tops'
			collectionList={COLLECTION_LISTS.BUILD_FLOW}
			refinementList={ALGOLIA_REFINEMENTS_BUILDFLOW}
			indexName={ALGOLIA_SEARCH_INDEXES[countryCode].RECENTLY_ORDERED}
			frame={frame}
			isSunglassesRoute={journey === CUSTOMER_JOURNEYS.SUNGLASSES}
		/>
	);
};

export default CollectionStep__BFFiltering;
