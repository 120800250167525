import * as AccessibleIcon from '@radix-ui/react-accessible-icon';
import variables from '@styles/export.module.scss';

type HeartSmallProps = {
	width?: string | number;
	height?: string | number;
	color?: string;
	label: string;
};

const HeartSmall = ({ width = 12, height = 10, color = variables.gray3, label }: HeartSmallProps) => {
	return (
		<AccessibleIcon.Root label={label}>
			<svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none'>
				<path
					d='M5.5709 1.7858L6 2.19098L6.4291 1.7858L6.88812 1.35236C7.91484 0.382873 9.59351 0.382872 10.6202 1.35236C11.6266 2.30263 11.6266 3.82767 10.6202 4.77794L6 9.14064L1.37978 4.77794C0.373408 3.82767 0.373408 2.30263 1.37978 1.35236C2.40649 0.382872 4.08516 0.382872 5.11188 1.35236L5.5709 1.7858Z'
					stroke={color}
					style={{
						stroke: color,
						strokeOpacity: 1,
					}}
					stroke-width='1.25'
				/>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default HeartSmall;
