import { useRef, useState } from 'react';
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { NavigationOptions } from 'swiper/types';
import { MOBILE_MAX_WIDTH } from '@constants';
import { Chevron, ConditionalLink, Heading, OldTestimonial } from '@components';
import { useWindowDimensions } from '@utils/hooks';

import { useIsWKWebView } from '@utils/check-for-wkwebview';
import styles from './OldTestimonials.module.scss';

const OldTestimonials = ({ heading, testimonialSlide, page, unoptimized }) => {
	const { width: viewportWidth } = useWindowDimensions();
	const navigationPrevRef = useRef(null);
	const navigationNextRef = useRef(null);
	const [isBeginning, setIsBeginning] = useState(false);
	const [isEnd, setIsEnd] = useState(false);
	const isWKWebView = useIsWKWebView();

	const navigationRefs = {
		prevEl: navigationPrevRef.current,
		nextEl: navigationNextRef.current,
	};

	if (!testimonialSlide.length) return null;
	return (
		<div className={styles.container} data-testimonial-slider data-component-type='testimonials' data-page-type={page}>
			<Heading tag='h3' data-testid='testimonials-heading'>
				{heading}
			</Heading>
			<Swiper
				data-testid='testimonials'
				direction='horizontal'
				spaceBetween={8}
				slidesPerView='auto'
				speed={500}
				modules={[Navigation, Autoplay]}
				navigation={viewportWidth < MOBILE_MAX_WIDTH ? false : navigationRefs}
				onSlideChange={swiper => {
					setIsBeginning(swiper.isBeginning);
					setIsEnd(swiper.isEnd);
				}}
				onSlidesGridLengthChange={swiper => {
					setIsBeginning(swiper.isBeginning);
					setIsEnd(swiper.isEnd);
					swiper.on('reachEnd', () => {
						setIsEnd(true);
					});
				}}
				onBeforeInit={swiper => {
					(swiper.params.navigation as NavigationOptions).prevEl = navigationPrevRef.current;
					(swiper.params.navigation as NavigationOptions).nextEl = navigationNextRef.current;
				}}
				autoplay={!isWKWebView}
			>
				<ul>
					{testimonialSlide.map((testimonial, index) => (
						<SwiperSlide key={`${testimonial.text}-${index}`} className={styles['container__swiper-slide']}>
							<ConditionalLink
								href={testimonial.featuredProductUrl}
								condition={!!testimonial.featuredProductUrl}
								reduceOpacityOnHover
								style={{ height: '100%' }}
							>
								<OldTestimonial unoptimized={unoptimized ? true : false} {...testimonial} />
							</ConditionalLink>
						</SwiperSlide>
					))}
				</ul>
				<div className={`${styles.prevButton} ${isBeginning ? styles.disable : ''}`} ref={navigationPrevRef}>
					<Chevron extraClasses={styles.chevron} direction='left' />
				</div>
				<div className={`${styles.nextButton} ${isEnd ? styles.disable : ''}`} ref={navigationNextRef}>
					<Chevron extraClasses={styles.chevron} direction='right' />
				</div>
			</Swiper>
		</div>
	);
};

export default OldTestimonials;
