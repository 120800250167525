import { useRouter } from 'next/router';
import { PRODUCT_TYPES, EMPTY_PRODUCT, LOCALE_CODES } from '@constants';
import { Caption, Detail, Flex, Img, LabelText } from '@components';
import { generateImgSrc } from '@utils/images';
import { formatCurrency, generateVariantCaption } from '@utils/shopify';
import { NormalizedCartLine } from '@ts/cart';
import { NormalizedProduct } from '@ts/product';
import styles from './MicroCard.module.scss';

type ProductType = (typeof PRODUCT_TYPES)[keyof typeof PRODUCT_TYPES];

function normalizeMicroCardData(data: NormalizedCartLine): { product: NormalizedProduct } {
	const { variant } = data;
	return {
		product: {
			...EMPTY_PRODUCT,
			...{
				id: variant?.product?.id,
				title: variant?.product?.name,
				handle: variant?.product?.handle,
				name: variant?.product?.name,
				productType: variant?.product?.type as ProductType,
				tags: variant?.product?.tags,
				variants: [variant],
			},
		},
	};
}

const MicroCard = ({ data }: { data: NormalizedCartLine }) => {
	const { product } = normalizeMicroCardData(data);
	const { locale } = useRouter();
	const variant = product?.variants?.[0];
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
	const formattedPrice = formatCurrency(
		{ amount: variant.price.amount, currencyCode: variant.price.currencyCode, locale },
		showCurr
	);
	const hasCompareAtPrice = !!variant?.compareAtPrice;
	const formattedCompareAtPrice = hasCompareAtPrice
		? formatCurrency(
				{ amount: variant.compareAtPrice.amount, currencyCode: variant.compareAtPrice.currencyCode, locale },
				showCurr
			)
		: null;

	return (
		<Flex fullWidth className={styles['microcard']}>
			<div className={styles['image-container']}>
				<Img
					className={styles['image']}
					src={generateImgSrc(variant.image.url, 160)}
					alt={product.name}
					aspectRatio={'7.2/8'}
					width={72}
					height={80}
					loading='eager'
					decoding='auto'
					noSrcset
					data-type={variant.type}
				/>
			</div>
			<Flex fullWidth justify='between' align='center' pad={3}>
				<Flex fullWidth column align='start' justify='center'>
					<LabelText>{product.name}</LabelText>
					<Detail>
						{product?.description && product.description.length > 0
							? product.description
							: generateVariantCaption(variant, variant.product.type)}
					</Detail>
				</Flex>
				<Caption small className={hasCompareAtPrice && styles['price-with-strikethrough']}>
					{formattedPrice}
				</Caption>
				{hasCompareAtPrice && <span className={styles['compare-price']}>{formattedCompareAtPrice}</span>}
			</Flex>
		</Flex>
	);
};

export default MicroCard;
