const NonRx = ({ className }) => {
	return (
		<svg viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M40.1183 20.1446C26.1029 23.7578 13.1114 28.1746 9.95075 37.8666C8.31834 42.8737 10.1302 50.7171 12.8498 56.781C18.6387 69.6884 26.3841 76.7674 40.1913 76.4765C45.1262 76.3736 51.687 75.4142 57.161 73.3274C61.0163 71.8581 64.4268 70.7476 67.4386 68.9681C76.4559 63.643 79.3302 54.507 80.4745 44.7975C81.3364 37.486 80.6818 26.7479 74.7866 21.3142C68.7703 15.7682 58.5198 16.9155 48.2297 18.3965L40.1183 20.1446Z'
				fill='#B5DEF4'
			/>
			<g opacity='0.4'>
				<path
					opacity='0.6'
					fillRule='evenodd'
					clipRule='evenodd'
					d='M79.7193 49.3427L48.4722 18.4316C50.0629 18.2075 51.6502 17.9951 53.219 17.8176L80.3563 44.663C80.3308 44.9268 80.3033 45.1867 80.2739 45.4425C80.1235 46.7556 79.9421 48.0582 79.7193 49.3427Z'
					fill='white'
				/>
				<path
					opacity='0.6'
					fillRule='evenodd'
					clipRule='evenodd'
					d='M79.232 48.9638L47.9849 18.0527C49.5756 17.8286 51.1629 17.6161 52.7317 17.4387L79.869 44.2842C79.8435 44.5479 79.816 44.8078 79.7867 45.0636C79.6362 46.3767 79.4548 47.6793 79.232 48.9638Z'
					fill='white'
				/>
				<path
					opacity='0.8'
					fillRule='evenodd'
					clipRule='evenodd'
					d='M70.5296 67.5951L26.6389 24.1766C30.6433 22.701 35.0414 21.457 39.5448 20.2963L39.6573 20.2721L77.459 57.6672C75.9554 61.442 73.7655 64.8401 70.5296 67.5951Z'
					fill='white'
				/>
			</g>
			<path
				d='M74 86C74 87.1046 62.1355 88 47.5 88C32.8645 88 21 87.1046 21 86C21 84.8954 32.8645 84 47.5 84C62.1355 84 74 84.8954 74 86Z'
				fill='#EFEDEE'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M80.6828 36.5126C79.227 37.176 77.8205 37.9569 76.4779 38.851L76.0674 39.1244L76.0996 38.632C76.4749 32.9014 75.2069 27.1822 72.4448 22.1471C73.4429 22.1275 74.4364 22.0584 75.4218 21.9409C78.8585 25.5673 80.3112 31.1301 80.6828 36.5126Z'
				fill='black'
			/>
			<path
				d='M85.4411 15.1756L85.4089 15.6681C85.0337 21.3986 86.3017 27.1178 89.0637 32.1529C83.3256 32.2653 77.7396 34.0149 72.9625 37.1961L72.552 37.4695L72.5843 36.977C72.9595 31.2465 71.6915 25.5273 68.9295 20.4921C74.6675 20.3798 80.2536 18.6302 85.0306 15.449L85.4411 15.1756Z'
				fill='#FF510E'
			/>
			<path
				d='M89.4759 36.1527L89.4639 36.3346C89.3254 38.4504 89.7936 40.5621 90.8134 42.4213C88.6948 42.4627 86.6322 43.1088 84.8684 44.2833L84.7168 44.3843L84.7287 44.2025C84.8673 42.0866 84.3991 39.9749 83.3793 38.1158C85.4979 38.0743 87.5605 37.4283 89.3243 36.2537L89.4759 36.1527Z'
				fill='#AAD6EF'
			/>
		</svg>
	);
};

export default NonRx;
