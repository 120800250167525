import { useRef } from 'react';
import cn from 'classnames';
import { Attention, Close, Box, Button, Flex, Paragraph, Title } from '@components';
import variables from '@styles/export.module.scss';
import styles from './NotificationBox.module.scss';

type NotificationBoxProps = {
	title: string;
	body: string;
	icon?: 'attention' | 'box';
	closeButton?: boolean;
	duration?: number;
	noBorder?: boolean;
	showTimer?: boolean;
	/**
	 * Heap Tracking of Notication upon Mounting of Component
	 *
	 * @example heap.track('Purchase', { dollars: 50, item: 'Furby' });
	 * @reference https://developers.heap.io/reference/track
	 */
	heap?: {
		eventName: string;
		eventProperties: Record<string, unknown>;
	};
	type?: 'neutral' | 'success' | 'error' | 'warning' | 'box';
	button?: boolean;
	backgroundColor?: string;
	buttonLinkStyle?: boolean;
	className?: string;
};

const NotificationBox = ({
	closeButton = false,
	title,
	body,
	icon = 'attention',
	button = false,
	backgroundColor = variables.gray1,
	buttonLinkStyle = false,
	className = '',
}: NotificationBoxProps) => {
	const boxRef = useRef(null);

	const removeBox = () => {
		boxRef.current.remove();
	};

	return (
		<div ref={boxRef} className={cn(styles.container, className)} style={{ background: backgroundColor }}>
			{closeButton && (
				<Close
					color='black'
					wrapperClass={styles.closeIconWrapper}
					onClick={() => removeBox()}
					label='Close this dialogue'
				/>
			)}
			<div className={styles.messageContainer}>
				{icon === 'box' ? (
					<Box />
				) : (
					<Attention width={20} height={20} fill={variables.gray5} label='Attention' style={{ height: '2.5rem' }} />
				)}
				<div className={styles.messageContent}>
					<Title className={styles.title}>{title}</Title>
					<Paragraph className={styles.body}>{body}</Paragraph>
					{button && (
						<Flex justify='end' style={{ marginTop: '1.6rem' }}>
							<Button
								href='https://paireyewear.com/collections/all-frames'
								color='transparent-dark'
								backgroundColor={variables.gray1}
								size='small'
								data-shop-base-frames
								linkStyle={buttonLinkStyle}
							>
								Shop All Base Frames
							</Button>
						</Flex>
					)}
				</div>
			</div>
		</div>
	);
};

export default NotificationBox;
