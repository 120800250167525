import { useRouter } from 'next/router';
import { CollectionSlider } from '@components';
import { normalizePomsFavorites } from '@utils/poms';
import { useFavorites } from '@services/poms';
import { Customer } from '@ts/shopify-storefront-api';
import { useIsMobile } from '@utils/hooks';
import { getShopifyIdFromGID } from '@utils/index';

const MyFavoritesSlider = ({ customer }: { customer: Customer }) => {
	const isMobile = useIsMobile();
	const { locale } = useRouter();
	const customerId = getShopifyIdFromGID(customer?.id);
	const { data: favorites, isLoading: isFavoritesLoading } = useFavorites(customerId);
	const products = [];

	favorites?.forEach(fav => {
		if (!fav.variant || !fav.available) {
			return null;
		}
		const parsedFav = normalizePomsFavorites({ locale, fav });
		products.push(parsedFav.product);
	});

	if (favorites?.length === 0 || (!isMobile && favorites?.length <= 3)) return null;
	return (
		<CollectionSlider
			iconType='heart'
			header='Explore Your Favorites'
			subHeader='Shop Tops you’ve had your eye on!'
			products={products.reverse()}
			buttonData={{
				text: 'View My Favorites',
				link: '/account/favorites',
			}}
			limit={8}
			isDataLoading={isFavoritesLoading}
			sliderType={'favorites'}
		/>
	);
};

export default MyFavoritesSlider;
