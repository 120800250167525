/* eslint-disable no-console */
import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './CircleTimer.module.scss';

type CircleTimerProps = {
	/**
	 * Executes when the timer reaches 0
	 * @returns void
	 */
	callback?: () => void;
	initialPlayState?: boolean;
	/**
	 * @default 10
	 */
	seconds?: number;
	/**
	 * @default 24
	 */
	size?: number;
	strokeWidth?: number;
	/**
	 * @default green-1 (#00ae4f)
	 */
	strokeColor?: string;
	/**
	 * @default gray-0 (#fff)
	 */
	strokeBgColor?: string;
};

const CircleTimer = ({
	callback,
	initialPlayState = false,
	seconds = 10,
	size = 24,
	strokeWidth = size / 6, // Accounts for inner size in proportion to strokewidth
	strokeColor,
	strokeBgColor,
}: CircleTimerProps) => {
	const milliseconds = seconds * 1000;
	const radius = size / 2;
	const circumference = size * Math.PI;

	const requestRef = useRef<number>();
	const timerStartRef = useRef<number>();
	const [isPlaying, setIsPlaying] = useState(initialPlayState);
	const [timeLeft, setTimeLeft] = useState(milliseconds);

	const strokeDashoffset = () => circumference - (timeLeft / milliseconds) * circumference;
	const step: FrameRequestCallback = useCallback(
		timestamp => {
			if (timerStartRef.current !== undefined) {
				const elapsedTime = timestamp - timerStartRef.current;
				const time = milliseconds - elapsedTime;
				setTimeLeft(time < 0 ? 0 : time);
			}
			requestRef.current = window.requestAnimationFrame(step);
		},
		[milliseconds]
	);

	useEffect(() => {
		if (isPlaying) {
			timerStartRef.current = performance.now();
			requestRef.current = window.requestAnimationFrame(step);
		}
		return () => window.cancelAnimationFrame(requestRef.current);
	}, [isPlaying, step]);

	useEffect(() => {
		if (timeLeft === 0) {
			callback();

			setTimeout(() => {
				setIsPlaying(false);
				setTimeLeft(milliseconds);
			}, 100);
		}
	}, [callback, milliseconds, timeLeft]);

	return (
		<>
			<div
				className={styles['container']}
				style={{
					width: `${size / 10}rem`,
					height: `${size / 10}rem`,
				}}
			>
				<svg className={styles['outer-ring']}>
					<circle
						className={styles['outer-ring__background']}
						r={radius - strokeWidth / 1.25}
						cx={radius}
						cy={radius}
						strokeWidth={strokeWidth / 2}
						{...{ stroke: strokeColor }}
					/>
				</svg>
				<svg className={styles['outer-ring']}>
					<circle
						className={styles['outer-ring__foreground']}
						r={radius - strokeWidth / 2}
						cx={radius}
						cy={radius}
						strokeDasharray={circumference}
						strokeDashoffset={isPlaying ? strokeDashoffset() : 0}
						strokeWidth={strokeWidth}
						{...{ stroke: strokeBgColor }}
					/>
				</svg>
				<svg
					className={styles['checkmark-icon']}
					viewBox='0 0 20 20'
					fill='none'
					width={size - strokeWidth - 1}
					height={size - strokeWidth - 1}
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM8.82988 13.9259L15.2084 7.52388L13.7916 6.11226L8.82729 11.0949L6.70727 8.97486L5.29305 10.3891L8.82988 13.9259Z'
					/>
				</svg>
			</div>
		</>
	);
};

export default CircleTimer;
