import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

const RemovePhoto = ({ color = 'white' }) => {
	return (
		<AccessibleIcon.Root label='removephoto'>
			<svg width='96' height='40' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M22.1012 28.0265L4.22923 7.08259L5.75061 5.78436L23.6226 26.7282L22.1012 28.0265Z'
					fill={color}
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M25.131 25.9842C26.0608 25.3191 26.6667 24.2303 26.6667 23V9C26.6667 6.97496 25.0251 5.33334 23 5.33334H9.00004C8.54641 5.33334 8.11202 5.41571 7.71101 5.56633L9.21858 7.33334H23C23.9205 7.33334 24.6667 8.07953 24.6667 9V14.0227L22.8727 11.9343C21.3707 10.1859 18.6484 10.2413 17.2182 12.0457L17.1973 12.0723C17.1159 12.1777 16.2783 13.2611 15.3231 14.4884L16.6484 16.0418C17.7071 14.6828 18.6915 13.4096 18.7797 13.2955L18.7855 13.2881C19.4355 12.468 20.6729 12.4429 21.3556 13.2376L24.5748 16.985C24.6038 17.0187 24.6345 17.05 24.6667 17.0788V23C24.6667 23.6215 24.3265 24.1636 23.8222 24.4501L25.131 25.9842ZM18.1606 20.8956C18.1121 20.9669 18.0535 21.033 17.985 21.0919C17.5661 21.4518 16.9347 21.4039 16.5748 20.985L13.8069 17.7629C13.7982 17.7532 13.7898 17.7433 13.7815 17.7334L13.3556 17.2376C12.6714 16.4411 11.43 16.4681 10.7811 17.2936L7.33337 21.6793V23C7.33337 23.9205 8.07957 24.6667 9.00004 24.6667H21.378L23.0835 26.6657C23.0558 26.6664 23.0279 26.6667 23 26.6667H9.00004C6.975 26.6667 5.33337 25.025 5.33337 23V21.3419V21.3234V9C5.33337 8.15552 5.61886 7.37772 6.09858 6.75784L7.46227 8.3562C7.37924 8.55428 7.33337 8.77178 7.33337 9V18.4432L9.2088 16.0576C10.1292 14.8867 11.5914 14.4461 12.9175 14.7503L18.1606 20.8956Z'
					fill={color}
				/>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default RemovePhoto;
