import React, { forwardRef } from 'react';
import { LabelText } from '@components';
import styles from './Flag.module.scss';
import type { ComponentProps } from '@ts/components';

type FlagProps = ComponentProps<HTMLDivElement> & {
	label?: string;
	backgroundColor?: string;
	color?: string;
};

const Flag = forwardRef<HTMLDivElement, FlagProps>(({ children, label, backgroundColor, color, ...rest }, ref) => {
	return (
		<div className={styles.flag} ref={ref} style={{ backgroundColor, color }} {...rest}>
			<LabelText>{children || label}</LabelText>
		</div>
	);
});

Flag.displayName = 'Flag';

export default Flag;
