import { ACCOUNT_TABS, YOTPO } from '@constants';
import { Heading, Paragraph, YotpoActivityWidgetBanner, YotpoWidget } from '@components';
import { useLoadYotpo } from '@services/yotpo';
import { Customer } from '@ts/shopify-storefront-api';
import styles from './Rewards.module.scss';

const Rewards = ({ customer }: { customer: Customer }) => {
	useLoadYotpo();
	if (!customer) return null;
	return (
		<div className={styles.myRewards}>
			<div className={styles.responsiveContentContainer}>
				<section>
					<Heading tag='h5' style={{ margin: '3.2rem 0', fontSize: '2.4rem' }}>
						{customer ? `Welcome back, ${customer.firstName}!` : ''}
					</Heading>
					<YotpoActivityWidgetBanner widgetId={YOTPO.ACTIVITY_WIDGET_STYLE2_ID} tab={ACCOUNT_TABS.MY_REWARDS} />
				</section>
				<section className={styles.campaignsSection}>
					<Heading style={{ lineHeight: '4rem', fontSize: '2.6rem' }} tag='h5'>
						Earn More Points
					</Heading>
					<Paragraph>Tap on the reward to learn more</Paragraph>
					<YotpoWidget widgetId={YOTPO.CAMPAIGNS_WIDGET_ID} />
				</section>
				<section>
					<Heading tag='h5'>Your Reward Level</Heading>
					<YotpoWidget widgetId={YOTPO.VIP_TIERS_WIDGET_ID} />
				</section>
			</div>
			<section>
				<YotpoWidget
					widgetId={YOTPO.REFER_A_FRIEND_WIDGET_ID}
					referral-views-friends-input-placeholder="Your Friends' Emails (Separated by Commas)"
				/>
			</section>
		</div>
	);
};

export default Rewards;
