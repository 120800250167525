import { useEffect } from 'react';
import Script from 'next/script';
import { useQuery } from '@tanstack/react-query';
import { YOTPO } from '@constants';
import { fetchStorefrontApi } from '@services/shopify';
import productIdQuery from '@services/shopify/queries/product-id-query';
import { getShopifyIdFromGID, normalizeHandle } from '@utils/shopify';
import styles from './YotpoReviews.module.scss';

const YotpoReviews = ({ product }) => {
	const { data: parentProductId, isLoading } = useQuery(['yotpo-review', product.id], async () => {
		const res = await fetchStorefrontApi(productIdQuery, {
			variables: {
				handle: normalizeHandle(product.handle, true),
			},
		});

		return getShopifyIdFromGID(res?.product?.id);
	});

	useEffect(() => {
		try {
			window?.yotpo?.refreshWidgets();
		} catch (error) {
			console.error(error);
		}
	}, [product, isLoading, parentProductId]);

	return (
		!isLoading && (
			<>
				<Script src={YOTPO.REVIEWS_SCRIPT_URL} />
				<section className={styles.container} data-section-name='yotpo-reviews' id='yotpo-reviews'>
					<div
						className={`yotpo yotpo-main-widget yotpo-reviews`}
						data-product-id={parentProductId ?? product.id}
						data-price={product.price.amount}
						data-currency={product.price.currencyCode}
						data-name={product.name}
						data-url={'https://paireyewear.com/' + product.handle}
						data-image-url={product.images[0].url}
					/>
				</section>
			</>
		)
	);
};

export default YotpoReviews;
