import { memo } from 'react';
import { Flex } from '@components';
import { ComponentButtonGrouping, ContentfulButtonFields } from '@ts/contentful';
import ContentfulButton from '../ContentfulButton';

const ButtonGrouping = ({ buttonComponents }: ComponentButtonGrouping) => {
	const buttons = buttonComponents.map((props: ContentfulButtonFields) => {
		return <ContentfulButton key={`contentful-button-${props.text + props.link}`} {...props} />;
	});

	return (
		<Flex gap={3} fullWidth>
			{buttons}
		</Flex>
	);
};

export default memo(ButtonGrouping);
