import { useCallback, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { GroupedVirtuoso, GroupedVirtuosoHandle } from 'react-virtuoso';
import { LOCALE_DICT, MEDIUM_WIDTH } from '@constants';
import { BuildFlowCard, CollectionBanner, CollectionDropdown, Flex, Loading, ProductGrid, ScrollBar } from '@components';
import createCutPaperPath from '@utils/create-cut-paper';
import { useIsMobile } from '@utils/hooks';
import { NormalizedBuildFlowCollection, NormalizedVariant } from '@ts/product';
import { useCollectionsQuery } from '@utils/react-query';
import { useBFContext } from '@context';
import styles from './CollectionStep.module.scss';

const cutPaperPath = createCutPaperPath('peach');

const CollectionStep = () => {
	const { collectionListSlug, frame } = useBFContext();
	const isMobile = useIsMobile({ maxWidth: MEDIUM_WIDTH });
	const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
	const virtuosoRef = useRef<GroupedVirtuosoHandle>(null);
	const containerRef = useRef<HTMLDivElement>(null);
	const router = useRouter();
	const { locale } = router;

	const { data: collectionsData } = useCollectionsQuery(collectionListSlug, {
		queryKey: `build-flow-collections-${frame}`,
		queryRefreshVars: [collectionListSlug, locale],
		type: 'buildflow',
		withProducts: true,
		skipVariants: true,
		includeSpecificFrameVariant: true,
		selectedOptions: [
			{
				name: 'Frame',
				value: frame,
			},
		],
		country: LOCALE_DICT[locale].countryCode,
	});

	let collections = collectionsData as NormalizedBuildFlowCollection[];
	const groupCountsMemo = useMemo(() => collections?.map(collection => collection.products.length), [collections]);

	// NOTE: Hide Sun Tops for Sunglasses
	if (router.pathname.includes('sunglasses'))
		collections = collections?.filter(collection => collection?.handle !== 'sun-tops');

	const handleSelectCollection = useCallback(index => {
		virtuosoRef.current.scrollToIndex({ align: 'start', index });
		setCurrentGroupIndex(index);
	}, []);

	const handleIntersecting = useCallback(([entry]: Array<IntersectionObserverEntry>) => {
		if (entry.isIntersecting) {
			const target = entry.target as HTMLElement;
			const groupIndex = parseInt(target.dataset.groupIndex);

			setCurrentGroupIndex(groupIndex);
		}
	}, []);

	const observer = useMemo(() => {
		if (typeof window !== 'undefined') {
			const options = { root: containerRef.current, rootMargin: '0px', threshold: isMobile ? [0.0001, 0.8] : [0.25, 0.75] };
			return new IntersectionObserver(handleIntersecting, options);
		}
	}, [handleIntersecting, isMobile]);

	/**
	 * NOTE: Generates tuple of React elements: [CollectionBanner, ProductGrid]
	 **/
	const groupingsTupleMemo = useMemo(
		() =>
			collections &&
			collections.length > 0 &&
			collections.map((collection: NormalizedBuildFlowCollection) => {
				if (collection.products.length < 1) return [null, null]; // MUST return [null, null] tuple if no products

				return [
					isMobile ? null : (
						<CollectionBanner
							key={`collection-header-${collection.handle}`}
							collection={collection}
							cutPaperPath={cutPaperPath}
						/>
					),
					<ProductGrid key={`product-grid-${collection.handle}`} title={collection.title} type='build-flow'>
						{collection.products.map((top: NormalizedVariant) => (
							<BuildFlowCard key={`top-card-${top.handle}`} top={top} />
						))}
					</ProductGrid>,
				];
			}),
		[collections, isMobile]
	);

	const groupedVirtuosoMemo = useMemo(() => {
		return (
			collections &&
			collections.length > 0 && (
				<GroupedVirtuoso
					className={styles['grouped-virtuoso']}
					ref={virtuosoRef}
					groupContent={() => <div style={{ height: '1px' }} />}
					itemContent={index => {
						return (
							<div
								key={`observer-wrapper-${index}`}
								data-group-index={index}
								ref={el => {
									if (el) {
										observer.observe(el);
									}
								}}
							>
								{groupingsTupleMemo[index]}
							</div>
						);
					}}
					overscan={{
						main: 2000,
						reverse: 6000,
					}}
					{...(isMobile ? { data: groupCountsMemo } : { groupCounts: collections.map(() => 1) })}
				/>
			)
		);
	}, [collections, isMobile, groupCountsMemo, groupingsTupleMemo, observer]);

	return (
		<div className={styles['container']} ref={containerRef}>
			<div className={styles['navbar']}>
				<Flex align='center' justify='center' className={styles['navbar-button-container']}>
					<CollectionDropdown collections={collections ?? []} handleSelectCollection={handleSelectCollection} />
				</Flex>
				<ScrollBar
					collections={collections ?? []}
					currentGroupIndex={currentGroupIndex}
					handleSelectCollection={handleSelectCollection}
				/>
			</div>
			{collections && collections.length > 0 ? groupedVirtuosoMemo : <Loading phrase='Loading Collections...' />}
		</div>
	);
};

export default CollectionStep;
