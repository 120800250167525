import Link from 'next/link';
import variables from '@styles/export.module.scss';
import { Caption, Checkmark, Flex, Heading, Img, Paragraph, TypographyButton } from '@components';
import { isVideoUrl } from '@utils/urls';
import Tooltip from '../Tooltip';
import styles from './LensOptions.module.scss';

type ColumnFields = {
	assetUrl: string;
	heading: string;
	description: string;
};

type LensOptionsProps = {
	title?: string;
	text?: string;
	columns?: ColumnFields[];
};

const LensOptions = ({
	title = 'Lens Options',
	text = 'Upgrade your lenses with premium ultra thin, progressive, and these below',
	columns = [
		{
			heading: 'Blue Light Filtering',
			description: 'Perfect for those who spend a lot of time in front of a screen',
			assetUrl:
				'https://videos.ctfassets.net/a8mjl2rcjwa7/3FS2rf6TmmU1ErDlwSVclV/e8611630ea4d8d3533943080a4521891/PAIR_Product_BlueLight.mp4',
		},
		{
			heading: 'Progressive',
			description: 'A modern day alternative for bifocal lenses',
			assetUrl:
				'https://videos.ctfassets.net/a8mjl2rcjwa7/3qc2cFqxeifkJcK50e7alr/700f71d65fe29e36a3cf9cf5ac9db323/PAIR_Icon_Bifocals.mp4',
		},
		{
			heading: 'Light Responsive',
			description: 'Lenses transition from clear to a darker tint when they are outside',
			assetUrl:
				'https://videos.ctfassets.net/a8mjl2rcjwa7/7l53lbAoE952TZEy8UqtNT/bfdd5e5b31fc2c5015d1db0c57cc3ccf/PAIR_Product_Progressive.mp4',
		},
		{
			heading: 'Sun Lens',
			description: 'Polarized, scratch-resistant lenses that block 100% of UV rays',
			assetUrl:
				'https://videos.ctfassets.net/a8mjl2rcjwa7/4TS99lToyt8ObauTMooFZI/1ece50c955e50b5bba712a208d7c13d4/PAIR_Product_Sunglasses.mp4',
		},
	],
}: LensOptionsProps) => {
	return (
		<div className={styles['container']} data-lens-options>
			<Heading tag='h2' className={styles['my-3']}>
				{title}
			</Heading>
			<Paragraph>{text}</Paragraph>
			<Flex justify={'between'} className={styles['reasons']}>
				{columns.map(column => (
					<Flex align={'center'} className={styles['reason']} key={column.heading}>
						{isVideoUrl(column.assetUrl) ? (
							<video playsInline autoPlay muted loop id='heroVideo'>
								<source src={column.assetUrl} type='video/mp4' />
								Your browser does not support the video tag.
							</video>
						) : (
							<Img
								style={{ objectFit: 'contain' }}
								src={column.assetUrl}
								noSrcset
								alt={column.heading}
								height={156}
								width={312}
							/>
						)}
						<Heading tag='h4'>{column.heading}</Heading>
						<Paragraph>{column.description}</Paragraph>
					</Flex>
				))}
			</Flex>

			<Flex justify={'evenly'} gap={4} className={styles['container__benefits']}>
				<Flex align='center' gap={3} className={styles['container__benefit']}>
					<Checkmark color={variables.green3} />
					<Caption>FSA & HSA eligible or insurance reimbursement</Caption>
					<Tooltip message={'Non-prescription sunglass lenses are not eligible for FSA or HSA.'} />
				</Flex>

				<Flex align='center' gap={3} className={styles['container__benefit']}>
					<Checkmark color={variables.green3} />
					<Caption>Free standard shipping and free returns</Caption>
				</Flex>

				<Flex align='center' gap={3} className={styles['container__benefit']}>
					<Checkmark color={variables.green3} />
					<Caption>30 day risk free trial</Caption>
				</Flex>
			</Flex>

			<Flex align='center' justify='evenly' className={styles['container__links']}>
				<Link href={'eyeglasses/women'}>
					<TypographyButton style={{ color: variables.blue2 }}>Shop Women</TypographyButton>
				</Link>
				<div className={styles['divider']}>|</div>
				<Link href={'eyeglasses/men'}>
					<TypographyButton style={{ color: variables.blue2 }}>Shop Men</TypographyButton>
				</Link>
				<div className={styles['divider']}>|</div>
				<Link href={'eyeglasses/kids'}>
					<TypographyButton style={{ color: variables.blue2 }}>Shop Kids</TypographyButton>
				</Link>
			</Flex>
		</div>
	);
};

export default LensOptions;
