import { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { NavigationOptions } from 'swiper/types';
import Script from 'next/script';
import { BULK_ORDERS_FORM_SCRIPT, MOBILE_MAX_WIDTH } from '@constants';
import { Chevron, Flex, Heading, Hero, HeroCarousel, Img, Paragraph } from '@components';
import { useWindowDimensions } from '@utils/hooks';
import { HeroCarouselFields, HeroFields } from '@ts/contentful';
import styles from './BulkOrders.module.scss';

type BulkOrdersProps = {
	hero: HeroCarouselFields;
	perfectFor: {
		backgroundColor: string;
		header: string;
		imageStacks: {
			image: {
				fileName: string;
				url: string;
				title: string;
			};
			heading: string;
			description: string;
		}[];
	};
	topsSlider: {
		header: string;
		images: {
			altText: string;
			url: string;
		}[];
	};
	bottomHero: HeroFields;
};

const BulkOrders = ({ hero, perfectFor, topsSlider, bottomHero }: BulkOrdersProps) => {
	const [isImageLoaded, setImageLoaded] = useState(false);
	const { width: viewportWidth } = useWindowDimensions();

	const [activeSlide, setActiveSlide] = useState(0);

	const navigationPrevRef = useRef(null);
	const navigationNextRef = useRef(null);
	const formRef = useRef(null);

	const navigationRefs = {
		prevEl: navigationPrevRef.current,
		nextEl: navigationNextRef.current,
	};

	const handleImageLoaded = () => {
		setImageLoaded(true);
	};
	return (
		<div>
			<HeroCarousel {...hero} />
			<Flex column align='center' className={styles.perfectFor} style={{ backgroundColor: perfectFor.backgroundColor }}>
				<Heading tag='h3' className={styles.header}>
					{perfectFor.header}
				</Heading>
				<div className={styles.badges}>
					{perfectFor.imageStacks.map(({ image, heading, description }) => (
						<Flex column align='center' key={image.fileName} className={styles.badge}>
							<div className={styles.image}>
								<Img src={image.url} noSrcset alt={image.title} width={116} height={116} />
							</div>
							<Heading tag='h6' className={styles.badgeHeader}>
								{heading}
							</Heading>
							<Paragraph>{description}</Paragraph>
						</Flex>
					))}
				</div>
			</Flex>
			<Flex column align='center' className={styles.carousel}>
				<Heading tag='h3' className={styles.header}>
					{topsSlider.header}
				</Heading>
				<Swiper
					className={styles.scroll}
					modules={[Navigation]}
					scrollbar={false}
					slidesPerView={viewportWidth < MOBILE_MAX_WIDTH ? 1.25 : 3.25}
					spaceBetween={16}
					navigation={viewportWidth < MOBILE_MAX_WIDTH ? false : navigationRefs}
					onInit={swiper => setActiveSlide(swiper.activeIndex)}
					onSlideChange={swiper => setActiveSlide(swiper.activeIndex)}
					onBeforeInit={swiper => {
						(swiper.params.navigation as NavigationOptions).prevEl = navigationPrevRef.current;
						(swiper.params.navigation as NavigationOptions).nextEl = navigationNextRef.current;
					}}
				>
					{topsSlider.images.map(({ altText, url }) => {
						const [heading, description] = altText.split(' - ');
						return (
							<SwiperSlide className={styles.itemCard} key={`${altText}-featured-card`}>
								<div>
									<Img
										alt='alt text'
										className={isImageLoaded ? styles.loaded : null}
										loading='lazy'
										onLoad={handleImageLoaded}
										src={url}
										noSrcset
										width={266}
										height={133}
									/>
								</div>
								<Heading tag='h6'>{heading}</Heading>
								<Paragraph>for The {description}</Paragraph>
							</SwiperSlide>
						);
					})}
					<div className={`${styles.prevButton} ${activeSlide === 0 ? styles.hide : ''}`} ref={navigationPrevRef}>
						<Chevron direction='left' color='#080593' />
					</div>

					<div
						className={`${styles.nextButton} ${activeSlide + 1 >= topsSlider.images.length - 2 ? styles.hide : ''}`}
						ref={navigationNextRef}
					>
						<Chevron direction='right' color='#080593' />
					</div>
				</Swiper>
			</Flex>
			<section id='bulkOrders'>
				<Flex ref={formRef} column align='center' className={styles.getInContact}>
					<Heading tag='h3' className={styles.formTitle}>
						Get in touch
					</Heading>
					<div
						data-tf-widget='V24jRJ51'
						data-tf-hide-headers
						data-tf-iframe-props='title=Bulk Order Contact'
						data-tf-medium='snippet'
						className={styles.formContainer}
					></div>
					<Script src={BULK_ORDERS_FORM_SCRIPT} strategy='lazyOnload' data-handle='paireyewear' id='bulkOrdersScript' />
				</Flex>
			</section>
			<Hero {...bottomHero} asBanner />
		</div>
	);
};

export default BulkOrders;
