import { Flex, Img } from '@components';
import styles from './GiftCardImage.module.scss';

const GiftCardImage = ({ selectedVariant }) => {
	return (
		<Flex className={styles.image} justify='center' align='center'>
			<Img src={selectedVariant.image.url} width={512} height={340} alt='gift card' />
		</Flex>
	);
};

export default GiftCardImage;
