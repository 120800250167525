import { forwardRef, useCallback } from 'react';
import Link from 'next/link';
import cn from 'classnames';
import { Flex, Heading, Lozenge } from '@components';
import { BADGE_TYPES } from '@constants';
import { NormalizedBuildFlowCollection, NormalizedCollection } from '@ts/product';
import Img from '../Img';
import styles from './CollectionTile.module.scss';

type CollectionTileProps = {
	collection: Omit<NormalizedCollection, 'products'> | Omit<NormalizedBuildFlowCollection, 'products'>;
	hasLink: boolean;
	onClick?: (args) => void;
};

//TODO: Ref forwarding
const CollectionTile = forwardRef<HTMLDivElement, CollectionTileProps>(({ collection, onClick, hasLink = true }, ref) => {
	const backgroundSrc = collection.metafields?.tileImage?.url ?? collection.image?.url;
	const logoSrc = collection.metafields?.smallCollectionLogo?.url || collection.metafields?.collectionLogoUrl;

	const TileContent = useCallback(() => {
		if (logoSrc) {
			return (
				<Flex column align='center' justify='center' className={styles['tile-name']}>
					<Img sizes='32rem' src={logoSrc} alt={`${collection.title} collection logo`} width={300} height={150} />
				</Flex>
			);
		}
		return (
			<Flex column align='start' justify='end' className={cn(styles['tile-name--noLogo'], styles['tile-name'])}>
				{collection.metafields?.isLimited && (
					<Lozenge
						shape='square'
						color={BADGE_TYPES.LIMITED.color}
						backgroundColor={BADGE_TYPES.LIMITED.backgroundColor}
						extraClasses={styles['tile-name--lozenge']}
					>
						{BADGE_TYPES.LIMITED.displayText}
					</Lozenge>
				)}

				<Heading tag='h6' style={{ textAlign: 'left' }} removeDefaultMargin>
					{collection.title}
				</Heading>
			</Flex>
		);
	}, []);

	const tile = (
		<div className={styles.tile} onClick={onClick} tabIndex={1} data-collection-tile={collection.handle} ref={ref}>
			<TileContent />
			<Img src={backgroundSrc} alt={`${collection.title} collection`} />
		</div>
	);

	return hasLink ? (
		<Link
			className={styles.tile}
			key={collection.handle}
			href={`top-frames/${collection.handle}`}
			aria-label={collection.handle}
		>
			{tile}
		</Link>
	) : (
		tile
	);
});

CollectionTile.displayName = 'CollectionTile';

export default CollectionTile;
