import Icon from '../../icons/Icon';

const DiamondFace = () => {
	return (
		<Icon label={'Diamond Face'}>
			<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M19.9497 3.36396L26.636 10.0503C29.3697 12.7839 29.3697 17.2161 26.636 19.9497L19.9497 26.636C17.2161 29.3697 12.7839 29.3697 10.0503 26.636L3.36396 19.9497C0.630286 17.2161 0.63029 12.7839 3.36396 10.0503L10.0503 3.36396C12.7839 0.630286 17.2161 0.63029 19.9497 3.36396Z'
					stroke='black'
					strokeWidth='2'
				/>
			</svg>
		</Icon>
	);
};

export default DiamondFace;
