import React, { useRef, useEffect } from 'react';
import cn from 'classnames';
import dynamic from 'next/dynamic';
import { m } from 'framer-motion';
import { Button, Caption, Flex, MuteIcon, Paragraph, SoundIcon, Title, Triangle } from '@components';
import { isVideoUrl } from '@utils/urls';
import { TestimonialProps } from '@ts/index';
import styles from './Testimonial.module.scss';

const DynamicImage = dynamic(() => import('next/image'), {
	ssr: false,
});

const Testimonial = ({ fields, unoptimized, isMuted, setIsMuted }: TestimonialProps) => {
	const { copyRight, header, featuredProductUrl, image, text } = fields || {};
	const isVideo = isVideoUrl(image?.fields.file.url);
	const videoRef = useRef(null);
	const containerRef = useRef(null);
	const buttonContainerRef = useRef(null);

	const toggleMute = () => {
		if (videoRef.current) {
			videoRef.current.muted = !isMuted;
			setIsMuted(!isMuted);
		}
	};

	useEffect(() => {
		if (videoRef.current) {
			videoRef.current.load();
		}
	}, []);

	if (!fields) return null;

	return (
		<li className={styles.container}>
			<Flex column>
				<div className={styles['container__image']} ref={containerRef}>
					{isVideo ? (
						<video
							ref={videoRef}
							playsInline
							muted={isMuted}
							loop
							id='tiktok'
							className={styles.video}
							preload='metadata'
						>
							<source src={fields.image.fields.file.url} type='video/mp4' />
						</video>
					) : (
						<DynamicImage
							unoptimized={unoptimized}
							src={fields.image.fields.file.url}
							alt={fields.text}
							fill
							sizes='30rem'
						/>
					)}
					<div
						className={cn(styles.gradientOverlay, {
							[styles['gradientOverlay--large-text']]: text.length > 150,
						})}
					/>
					<Caption className={styles.copyright}>{copyRight}</Caption>
					{isVideo && (
						<Flex className={styles.playIcon}>
							<Triangle />
						</Flex>
					)}
					<m.div
						className={styles.content}
						initial='expanded'
						animate={'expanded'}
						variants={{
							collapsed: { bottom: '-10rem', opacity: 0 },
							expanded: { bottom: '0', opacity: 1 },
						}}
						transition={{ ease: 'easeInOut', stiffness: 256, damping: 24, duration: 0.5, mass: 1 }}
					>
						<Flex column className={styles.textContainer}>
							<Title>{header && header}</Title>
							<Paragraph>{text}</Paragraph>
							{copyRight && <Paragraph>📷: {copyRight}</Paragraph>}
						</Flex>
						<Flex className={styles.ctaContainer} ref={buttonContainerRef}>
							<Flex gap={3}>
								<Button size='small' label='Shop Now' href={featuredProductUrl} color='transparent-light' />
								{isVideo && (
									<Button size='small' label='' color='transparent-light' onClick={toggleMute}>
										{isMuted ? <MuteIcon /> : <SoundIcon />}
									</Button>
								)}
							</Flex>
						</Flex>
					</m.div>
				</div>
			</Flex>
		</li>
	);
};

export default Testimonial;
