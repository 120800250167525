import { useMemo, useRef } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { LOCALE_DICT } from '@constants';
import { CollectionBanner } from '@components/buildflow';
import { BuildFlowCard } from '@components/card';
import { ProductGrid } from '@components/collections';
import { Loading } from '@components/common';
import { NormalizedBuildFlowCollection, NormalizedVariant } from '@ts/product';
import { useCollectionsQuery } from '@utils/react-query';
import { useBFContext } from '@context';
import styles from './CollectionStepSubscription.module.scss';

const CollectionStepSubscription = () => {
	const { frame } = useBFContext();
	const containerRef = useRef<HTMLDivElement>(null);
	const router = useRouter();
	const { locale } = router;

	const { data: collectionsData } = useCollectionsQuery('subscription-collection', {
		queryKey: `build-flow-subs-collections-${frame}`,
		queryRefreshVars: [locale],
		type: 'buildflow',
		withProducts: true,
		skipVariants: true,
		includeSpecificFrameVariant: true,
		selectedOptions: [
			{
				name: 'Frame',
				value: frame,
			},
		],
		country: LOCALE_DICT[locale].countryCode,
	});

	let collections = collectionsData as NormalizedBuildFlowCollection[];

	if (router.pathname.includes('sunglasses'))
		collections = collections?.filter(collection => collection?.handle !== 'sun-tops');

	const collectionGrid = useMemo(
		() =>
			collections &&
			collections.length > 0 &&
			collections.map((collection: NormalizedBuildFlowCollection) => {
				if (collection.products.length < 1) return [null, null];

				return (
					<>
						<CollectionBanner
							key={`collection-header-${collection.handle}`}
							collection={collection}
							title='Add Tops for One-Time Purchase'
							extraClasses={cn(styles['collection-banner'], {
								[styles['collection-banner--fullWidth']]: false,
							})}
						/>
						<ProductGrid
							key={`product-grid-${collection.handle}`}
							title={collection.title}
							type='build-flow-with-subscription'
						>
							{collection.products.map((top: NormalizedVariant) => (
								<BuildFlowCard key={`top-card-${top.handle}`} top={top} />
							))}
						</ProductGrid>
					</>
				);
			}),
		[collections]
	);

	return (
		<div className={styles['container']} ref={containerRef}>
			{collections && collections.length > 0 ? (
				<div className={styles['collection-container']}>{collectionGrid}</div>
			) : (
				<Loading phrase='Loading Collections...' />
			)}
		</div>
	);
};

export default CollectionStepSubscription;
