import { Flex } from '@components';
import { SubmissionMethod } from '@ts/poms';

interface Emoji {
	bluelight: boolean;
	premium: boolean;
	submissionMethod: SubmissionMethod;
	insured: boolean;
	progressive: boolean;
	readers: boolean;
}
const EmojiStatus = ({ submissionMethod, bluelight, premium, insured }: Partial<Emoji>) => (
	<Flex align='baseline' gap={4}>
		{insured && <div>🤞</div>}
		{submissionMethod === 'remind' && <div>⏳</div>}
		{submissionMethod === 'photo' && <div>📸</div>}
		{submissionMethod === 'doctor' && <div>👩‍⚕️</div>}
		{bluelight && <div>🛋</div>}
		{premium && <div>🤑</div>}
	</Flex>
);

export default EmojiStatus;
