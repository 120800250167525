import Icon from '../../icons/Icon';

const OblongFace = () => {
	return (
		<Icon label='Oblong Face'>
			<svg width='27' height='36' viewBox='0 0 27 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M4.4547 7.5645C5.73138 3.64936 9.38196 1 13.5 1C17.618 1 21.2686 3.64936 22.5453 7.5645L24.2296 12.7296C25.3464 16.1545 25.3464 19.8455 24.2296 23.2703L22.5453 28.4355C21.2686 32.3506 17.618 35 13.5 35C9.38196 35 5.73138 32.3506 4.4547 28.4355L2.77042 23.2704C1.65363 19.8455 1.65363 16.1545 2.77042 12.7296L4.4547 7.5645Z'
					stroke='black'
					strokeWidth='2'
				/>
			</svg>
		</Icon>
	);
};

export default OblongFace;
