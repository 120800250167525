import { useForm } from 'react-hook-form';
import { Button, Caption, Flex, Heading, Input, Paragraph, Tooltip } from '@components';
import variables from '@styles/export.module.scss';
import { MIN_VALUE } from '@constants';

type OrderNumberFormProps = {
	setOrder: (order: string) => void;
};

const OrderNumberForm = ({ setOrder }: OrderNumberFormProps) => {
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm();
	const orderNumber = watch('orderNumber');

	const onSubmit = data => {
		if (!errors.orderNumber) {
			setOrder(data.orderNumber);
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} role='document'>
			<Flex gap={3} column style={{ margin: 'auto' }}>
				<Heading tag='h4'>Enter your order info</Heading>
				<Paragraph>We just need your order number to link your PD with your Pair!</Paragraph>
				<Flex>
					<Input
						{...register('orderNumber', {
							required: 'Please enter your order number',
							pattern: { value: /^\d+$/, message: 'The order number should have digits only' },
							minLength: {
								value: MIN_VALUE,
								message: `Your order number should be 5 characters or more.`,
							},
						})}
						placeholder='Order Number'
						errorMessage={errors.orderNumber?.message as string}
					/>
				</Flex>
				<Button
					type='submit'
					style={{ width: '100%' }}
					disabled={!orderNumber || orderNumber.length === 0 || !!errors.orderNumber}
				>
					Submit
				</Button>
				<Flex justify={'center'} gap={2} style={{ margin: '1.6rem' }}>
					<Tooltip message='Check your email for an order confirmation, or reach out to us via chat!'>
						<Caption style={{ color: variables.blue2 }}>Help me find my order number</Caption>
					</Tooltip>
				</Flex>
			</Flex>
		</form>
	);
};

export default OrderNumberForm;
