import * as AccessibleIcon from '@radix-ui/react-accessible-icon';
import variables from '@styles/export.module.scss';

type HeartEmptyProps = {
	label: string;
	width?: number;
	height?: number;
};

const HeartEmpty = ({ label, width = 64, height = 64 }: HeartEmptyProps) => {
	return (
		<AccessibleIcon.Root label={label}>
			<svg
				width={width}
				height={height}
				viewBox={`0 0 ${width + 16} ${height}`}
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M12.9232 36.923C6.12581 30.1257 6.12581 19.105 12.9232 12.3077C19.7205 5.51033 30.7412 5.51033 37.5385 12.3077L64.6154 39.3846L40.0001 63.9999L12.9232 36.923Z'
					fill={variables.gray2}
				/>
				<path
					d='M42.4616 12.3077C49.259 5.51033 60.2796 5.51034 67.077 12.3077C73.8743 19.105 73.8743 30.1257 67.077 36.9231L40.0001 63.9999L15.3847 39.3846L42.4616 12.3077Z'
					fill={variables.gray2}
				/>
				<path
					d='M41.8028 36.0899C44.54 35.8834 46.6155 33.8778 46.6155 30.8104C46.6155 27.566 44.3295 25 40.1184 25C34.4335 25 33.5311 29.1587 33.6213 31.1643H37.682C37.682 30.073 38.1031 28.4213 40.1184 28.4213C41.5923 28.4213 42.5548 29.4242 42.5548 30.8399C42.5548 32.4326 41.502 33.1994 39.9981 33.1994H37.8023V40.1011H41.8028V36.0899ZM39.8176 46C41.2313 46 42.3442 44.9087 42.3442 43.493C42.3442 42.1067 41.2313 41.0154 39.8176 41.0154C38.4039 41.0154 37.2609 42.1067 37.2609 43.493C37.2609 44.9087 38.4039 46 39.8176 46Z'
					fill={variables.gray3}
				/>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default HeartEmpty;
