import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { EMAIL_VALIDATION_REG_EX, NUMBERS_VALIDATION_REG_EX } from '@constants';
import { Button, Flex, Heading, Input, NotificationBox } from '@components';
import { getOrderByOrderNumber } from '@services/shopify/operations/admin';
import { useCustomer } from '@services/shopify';
import styles from './TrackOrderForm.module.scss';

type TrackOrderInput = {
	email: string;
	orderNumber: string;
};

const TrackOrderForm = () => {
	const router = useRouter();
	const Customer = useCustomer();
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm<TrackOrderInput>({
		mode: 'onBlur',
		defaultValues: {
			email: '',
			orderNumber: '',
		},
	});

	const [infoAlert, setInfoAlert] = useState<string | null>();
	const [loading, setLoading] = useState(false);

	const onSubmit: SubmitHandler<TrackOrderInput> = async data => {
		setLoading(true);
		const response = await getOrderByOrderNumber(data.orderNumber, data.email);
		setInfoAlert(response.length == 0 ? 'Please enter a valid order number and email address.' : null);
		if (response.length > 0) {
			const path = `/track-order/${data.orderNumber}?email=${data.email}`;
			router.push(path);
			return;
		}
		setLoading(false);
	};

	const currentEmailInput = watch('email');
	const currentOrderNumberInput = watch('orderNumber');
	const isValidEmail: boolean = EMAIL_VALIDATION_REG_EX.test(currentEmailInput);
	const isValidOrderNumber: boolean = NUMBERS_VALIDATION_REG_EX.test(currentOrderNumberInput);

	useEffect(() => {
		if (Customer.data) {
			router.push('/account/orders');
		}
	}, [Customer, router]);

	const showForm = !Customer.isLoading && !Customer.data;

	return (
		<>
			{showForm && (
				<Flex column align='center' justify='center'>
					<form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
						<Heading tag='h4' style={{ textAlign: 'center' }}>
							View or Manage Your Order
						</Heading>
						<div className={styles.subheader}>
							To check the status of your order, please enter in your order number and email address
						</div>
						{infoAlert && (
							<NotificationBox title='Not Found' body={infoAlert} type='error' className={styles.notification} />
						)}
						<div className={styles.form}>
							<Input
								{...register('orderNumber', {
									required: 'Please fill out this field.',
									pattern: {
										value: NUMBERS_VALIDATION_REG_EX,
										message: 'Please only enter in numeric values',
									},
								})}
								onKeyDown={event => ['.', '-', '+', 'e', 'E'].includes(event.key) && event.preventDefault()}
								errorMessage={errors?.orderNumber?.message}
								id='CustomerOrderNumber'
								name='orderNumber'
								placeholder='Order Number'
								type='number'
								min='0'
								className={styles.input}
								pattern='\d*'
							/>
							<Input
								{...register('email', {
									required: 'Please fill out this field.',
									pattern: {
										value: EMAIL_VALIDATION_REG_EX,
										message: `Invalid email address`,
									},
								})}
								errorMessage={errors?.email?.message}
								id='TrackOrderCustomerEmail'
								name='email'
								placeholder='Email address'
								type='email'
								className={styles.input}
							/>
						</div>
						<div className={styles.buttons}>
							<Button
								showSpinner={loading}
								fullWidth
								type='submit'
								label='Submit'
								disabled={!(isValidEmail && isValidOrderNumber && currentOrderNumberInput.length > 0)}
							/>
						</div>
						<div className={styles.footer}>
							<div className={styles['footer-text']}>Want to keep track of all your orders and earn rewards?</div>
							<Button
								linkStyle
								size='small'
								label='Create a free Pair account'
								href={`/account/register?previous=${router.asPath}`}
							/>
						</div>
					</form>
				</Flex>
			)}
		</>
	);
};
export default TrackOrderForm;
