import {
	useState,
	useEffect,
	forwardRef,
	useRef,
	ReactNode,
	createContext,
	useContext,
	useMemo,
	useCallback,
	ForwardRefExoticComponent,
	RefAttributes,
} from 'react';
import { Flex } from '@components';
import { ComponentProps } from '@ts/components';
import styles from './WheelPicker.module.scss';

interface WheelPicker extends ForwardRefExoticComponent<WheelPickerProps & RefAttributes<HTMLDivElement>> {
	Arm: typeof Arm;
}

type WheelPickerProps = ComponentProps<HTMLDivElement> & {
	children: ReactNode;
	itemHeight?: number;
	parentHeight?: number;
	rotationEffect?: boolean;
	visibleOptions?: number;
};

type ArmProps = Omit<WheelPickerProps, 'children' | 'title' | 'handleHeaderNavigation'> & {
	/** If an unselectable instruction option has been provided, setting this value true will render the instruction in the middle of the value array */
	centerInstruction?: boolean;
	defaultValue?: string;
	instruction?: string;
	onChange: (value: string, i?: number) => void;
	values: string[];
	textAlign?: 'left' | 'center' | 'right';
	dataTags?: Record<string, unknown>;
};

const WheelPickerContext = createContext({
	itemHeight: 40,
	parentHeight: 200,
	visibleOptions: 3,
	rotationEffect: false,
});

//-------------------------//
//-------------------------//
//-------------------------//

/**
 * Wheel Picker Arm
 */
const Arm = forwardRef<HTMLDivElement, ArmProps>(
	({ values, defaultValue, onChange, instruction, centerInstruction, textAlign = 'center', dataTags }, ref) => {
		const { itemHeight, parentHeight, visibleOptions, rotationEffect } = useContext(WheelPickerContext);
		const listRef = useRef<HTMLDivElement>();
		const timeoutRef = useRef<NodeJS.Timeout>();
		const selectedValueRef = useRef<HTMLDivElement>();

		//--- Value Mapping ---//
		const valuesToMapMemo = useMemo(() => {
			const copiedValues = [...values];
			if (instruction && centerInstruction) {
				copiedValues.splice(copiedValues.length / 2, 0, instruction);
				return copiedValues;
			}

			if (instruction) copiedValues.splice(0, 0, instruction);
			return copiedValues;
		}, [centerInstruction, instruction, values]);

		const [selectedItemIndex, setSelectedItemIndex] = useState(
			valuesToMapMemo.indexOf(defaultValue ?? instruction ?? valuesToMapMemo[0])
		);

		//--- Functionality ---//
		const handleScroll = useCallback(
			(e: Event) => {
				// Clear the timeout if it exists to defer the "finish scrolling" callback
				if (timeoutRef.current) {
					clearTimeout(timeoutRef.current);
				}

				// Determine which item should be selected
				// Capture the scroll depth within the picker arm
				const scrollValue = (e.target as HTMLElement).scrollTop;
				// Shouldn't be exact -- If the user has scrolled at least halfway into an item, select it
				const itemInSelectorArea = Math.floor((scrollValue + itemHeight / 2) / itemHeight);

				// ! Leaving as an example -- setting the item index prematurely is too expensive, so we'll wait until the user has finished scrolling
				// if (itemInSelectorArea < valuesToMapMemo.length) {
				// 	setSelectedItemIndex(itemInSelectorArea);
				// }

				// After 150ms of no scrolling, assume the user has finished scrolling, and fire the callback
				timeoutRef.current = setTimeout(() => {
					valuesToMapMemo[itemInSelectorArea] !== instruction && onChange(valuesToMapMemo[itemInSelectorArea]);
					const y = Math.max(itemInSelectorArea * itemHeight - 1, 0);
					listRef.current?.scroll({
						top: y,
						behavior: 'smooth',
					});
					if (itemInSelectorArea < valuesToMapMemo.length) {
						setSelectedItemIndex(itemInSelectorArea);
					}
				}, 150);
			},
			[instruction, itemHeight, onChange, valuesToMapMemo]
		);

		const handleItemClick = useCallback(
			(index: number, instant?: boolean) => {
				listRef.current.scroll({
					top: index * itemHeight - 1,
					behavior: instant ? ('instant' as ScrollBehavior) : 'smooth',
				});
			},
			[itemHeight]
		);

		// Scroll to Selected
		useEffect(() => {
			if (selectedValueRef.current) {
				selectedValueRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
			}
		}, [selectedItemIndex]);

		const applyRotation = useCallback(
			(i: number, selectedItemIndex: number) => {
				const distanceFromSelected = Math.abs(selectedItemIndex - i);
				const rotationDegrees = 0 + distanceFromSelected * (45 / (visibleOptions - 1));
				return `rotateX(${Math.min(rotationDegrees, 45)}deg)`;
			},
			[visibleOptions]
		);

		useEffect(() => {
			const listElement = listRef.current;
			const timeoutId = timeoutRef.current;

			// On mount, set up the scroll listener
			listElement.addEventListener('scroll', handleScroll);
			// Scroll to the selected item, the index of which may be controlled by a parent. Pass "true" to scroll instantly
			handleItemClick(selectedItemIndex, true);

			return () => {
				if (timeoutId) {
					clearTimeout(timeoutId);
				}
				if (listElement) {
					listElement.removeEventListener('scroll', handleScroll);
				}
			};
		}, [handleItemClick, handleScroll, selectedItemIndex]);

		useEffect(() => {
			handleItemClick(valuesToMapMemo.indexOf(defaultValue ?? instruction), true);
		}, [defaultValue, handleItemClick, instruction, valuesToMapMemo]);

		const mappedValues = valuesToMapMemo.map((item, i) => (
			<div
				key={`wheel-item-${item}`}
				className={styles.itemContainer}
				style={{ minHeight: `${itemHeight}px`, maxHeight: `${itemHeight}px` }}
			>
				<div
					className={`${styles.item} ${i === selectedItemIndex ? styles.selectedItem : ''}`}
					ref={i === selectedItemIndex ? selectedValueRef : undefined}
					style={{
						minHeight: itemHeight + 'px',
						maxHeight: itemHeight + 'px',
						textAlign,
						...(rotationEffect && { transform: applyRotation(i, selectedItemIndex) }),
					}}
					onClick={() => {
						if (i === selectedItemIndex) {
							return;
						}
						handleItemClick(i);
					}}
				>
					{item}
				</div>
			</div>
		));

		return (
			<Flex fullWidth>
				<div ref={ref} className={styles.container} style={{ height: `${parentHeight / 10}rem` }}>
					<div className={styles.list} ref={listRef}>
						<div
							style={{
								paddingTop: `${parentHeight / 2 - itemHeight / 2}px`,
								paddingBottom: `${parentHeight / 2 - itemHeight / 2}px`,
							}}
							{...dataTags}
						>
							{mappedValues}
						</div>
					</div>
				</div>
			</Flex>
		);
	}
);

/**
 * Wheel Picker Container
 */
const WheelPickerContainer = forwardRef<HTMLDivElement, WheelPickerProps>(
	({ itemHeight = 40, parentHeight, children, visibleOptions = 3, rotationEffect = false, ...rest }, ref) => {
		return (
			<Flex ref={ref} position='relative' {...rest}>
				<WheelPickerContext.Provider
					value={{
						itemHeight,
						parentHeight,
						visibleOptions,
						rotationEffect,
					}}
				>
					<div className={styles.fadeArea} style={{ height: `calc(50% - ${itemHeight / 20}rem)` }} />
					<div className={styles.fadeArea} style={{ height: `calc(50% - ${itemHeight / 20}rem)` }} />
					<div className={styles.selectorArea} style={{ height: `${itemHeight / 10}rem` }} />
					{children}
				</WheelPickerContext.Provider>
			</Flex>
		);
	}
);

//-------------------------//
//-------------------------//
//-------------------------//

// Export Information
Arm.displayName = 'Arm';
WheelPickerContainer.displayName = 'WheelPickerContainer';

const WheelPicker = {
	...WheelPickerContainer,
	Arm,
} as WheelPicker;

export default WheelPicker;
