import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

const MuteIcon = () => {
	return (
		<AccessibleIcon.Root label='Mute Icon'>
			<svg xmlns='http://www.w3.org/2000/svg' width='27' height='25' viewBox='0 0 27 25' fill='none'>
				<g filter='url(#filter0_d_2964_3906)'>
					<path
						fill-rule='evenodd'
						clip-rule='evenodd'
						d='M11.5 7V19L7.5 16H5.5C4.94772 16 4.5 15.5523 4.5 15V11C4.5 10.4477 4.94772 10 5.5 10H7.5L11.5 7ZM17.5 13.7071L14.8536 16.3536C14.6583 16.5488 14.3417 16.5488 14.1464 16.3536C13.9512 16.1583 13.9512 15.8417 14.1464 15.6464L16.7929 13L14.1464 10.3536C13.9512 10.1583 13.9512 9.84171 14.1464 9.64645C14.3417 9.45118 14.6583 9.45118 14.8536 9.64645L17.5 12.2929L20.1464 9.64645C20.3417 9.45118 20.6583 9.45118 20.8536 9.64645C21.0488 9.84171 21.0488 10.1583 20.8536 10.3536L18.2071 13L20.8536 15.6464C21.0488 15.8417 21.0488 16.1583 20.8536 16.3536C20.6583 16.5488 20.3417 16.5488 20.1464 16.3536L17.5 13.7071Z'
						fill='white'
					/>
				</g>
				<defs>
					<filter
						id='filter0_d_2964_3906'
						x='-2'
						y='-1.5'
						width='32'
						height='32'
						filterUnits='userSpaceOnUse'
						color-interpolation-filters='sRGB'
					>
						<feFlood flood-opacity='0' result='BackgroundImageFix' />
						<feColorMatrix
							in='SourceAlpha'
							type='matrix'
							values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
							result='hardAlpha'
						/>
						<feOffset dx='2' dy='2' />
						<feGaussianBlur stdDeviation='2' />
						<feComposite in2='hardAlpha' operator='out' />
						<feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0' />
						<feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_2964_3906' />
						<feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_2964_3906' result='shape' />
					</filter>
				</defs>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default MuteIcon;
