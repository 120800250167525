import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

const Klarna = () => {
	return (
		<AccessibleIcon.Root label='klarna'>
			<svg width='48' height='10' viewBox='0 0 48 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<g clipPath='url(#clip0_2301_180438)'>
					<path
						d='M8.96532 0H6.68067C6.68067 1.81009 5.82008 3.46192 4.3243 4.54995L3.42273 5.20277L6.92655 9.81207H9.80542L6.57822 5.56874C8.10473 4.10485 8.96532 2.12661 8.96532 0Z'
						fill='black'
					/>
					<path d='M3.13587 0H0.799988V9.81207H3.13587V0Z' fill='black' />
					<path d='M12.6843 0H10.4816V9.81207H12.6843V0Z' fill='black' />
					<path
						d='M32.0065 2.83887C31.1664 2.83887 30.3673 3.08615 29.8346 3.78842V3.0268H27.7446V9.81216H29.8653V6.25133C29.8653 5.22265 30.5825 4.71819 31.443 4.71819C32.3651 4.71819 32.8978 5.25232 32.8978 6.24144V9.82205H34.9981V5.4996C34.9981 3.91701 33.697 2.83887 32.0065 2.83887Z'
						fill='black'
					/>
					<path
						d='M19.3436 3.0268V3.46201C18.7494 3.06636 18.0322 2.83887 17.2536 2.83887C15.2046 2.83887 13.5449 4.44124 13.5449 6.41948C13.5449 8.39772 15.2046 10.0001 17.2536 10.0001C18.0322 10.0001 18.7494 9.7726 19.3436 9.37695V9.81216H21.4438V3.0268H19.3436ZM17.4277 8.16033C16.3725 8.16033 15.5222 7.37893 15.5222 6.41948C15.5222 5.46003 16.3725 4.67863 17.4277 4.67863C18.483 4.67863 19.3333 5.46003 19.3333 6.41948C19.3333 7.37893 18.483 8.16033 17.4277 8.16033Z'
						fill='black'
					/>
					<path
						d='M24.7018 3.90693V3.02661H22.5503V9.81197H24.712V6.64679C24.712 5.57854 25.9107 5.00485 26.7405 5.00485C26.7508 5.00485 26.761 5.00485 26.761 5.00485V3.02661C25.9107 3.02661 25.1218 3.38269 24.7018 3.90693Z'
						fill='black'
					/>
					<path
						d='M41.5344 3.0268V3.46201C40.9402 3.06636 40.2231 2.83887 39.4444 2.83887C37.3954 2.83887 35.7357 4.44124 35.7357 6.41948C35.7357 8.39772 37.3954 10.0001 39.4444 10.0001C40.2231 10.0001 40.9402 9.7726 41.5344 9.37695V9.81216H43.6347V3.0268H41.5344ZM39.6186 8.16033C38.5634 8.16033 37.713 7.37893 37.713 6.41948C37.713 5.46003 38.5634 4.67863 39.6186 4.67863C40.6738 4.67863 41.5242 5.46003 41.5242 6.41948C41.5344 7.37893 40.6738 8.16033 39.6186 8.16033Z'
						fill='black'
					/>
					<path
						d='M45.2637 3.22442C45.2637 3.12551 45.1919 3.06616 45.0792 3.06616H44.8846V3.5805H44.9768V3.39257H45.0792L45.1612 3.5805H45.2637L45.1714 3.37279C45.2329 3.34312 45.2637 3.29366 45.2637 3.22442ZM45.0792 3.30355H44.9768V3.14529H45.0792C45.1407 3.14529 45.1714 3.17497 45.1714 3.22442C45.1714 3.27388 45.151 3.30355 45.0792 3.30355Z'
						fill='black'
					/>
					<path
						d='M45.0485 2.84863C44.7719 2.84863 44.5465 3.06624 44.5465 3.3333C44.5568 3.60036 44.7719 3.81797 45.0485 3.81797C45.3251 3.81797 45.5505 3.60036 45.5505 3.3333C45.5505 3.06624 45.3251 2.84863 45.0485 2.84863ZM45.0485 3.72895C44.8231 3.72895 44.649 3.55091 44.649 3.3333C44.649 3.1157 44.8334 2.93765 45.0485 2.93765C45.2739 2.93765 45.4481 3.1157 45.4481 3.3333C45.4481 3.55091 45.2637 3.72895 45.0485 3.72895Z'
						fill='black'
					/>
					<path
						d='M45.8784 7.40845C45.151 7.40845 44.5568 7.98214 44.5568 8.68441C44.5568 9.38669 45.151 9.96038 45.8784 9.96038C46.6058 9.96038 47.2 9.38669 47.2 8.68441C47.2 7.97225 46.6058 7.40845 45.8784 7.40845Z'
						fill='black'
					/>
				</g>
				<defs>
					<clipPath id='clip0_2301_180438'>
						<rect width='46.4' height='10' fill='white' transform='translate(0.799988)' />
					</clipPath>
				</defs>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default Klarna;
