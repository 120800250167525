const customerResetPasswordMutation = /* GraphQL */ `
	mutation customerResetPassword($id: ID!, $input: CustomerResetInput!) {
		customerReset(id: $id, input: $input) {
			customer {
				id
			}
			customerAccessToken {
				accessToken
				expiresAt
			}
			customerUserErrors {
				code
				field
				message
			}
		}
	}
`;

export default customerResetPasswordMutation;
