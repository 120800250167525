import React from 'react';
import { Button } from '@components';
import { useCartContext } from '@context';
import { useCart } from '@services/shopify';

/**
 * Clears all line items from the cart
 */
const WipeCart = () => {
	const { data: cart } = useCart();
	const { handleCartRemove } = useCartContext();
	return (
		<Button
			fullWidth
			color='transparent-dark'
			label='🧨 Wipe Cart 🧨'
			onClick={() => handleCartRemove(cart.lines.map(line => line.id))}
		/>
	);
};

export default WipeCart;
