import { Flex, Heading } from '@components';
import { MarketingBlocksProps } from '@ts/contentful';
import MarketingBlock from '../MarketingBlock';
import styles from './MarketingBlocks.module.scss';

const MarketingBlocks = ({ title, marketingBlocks, page }: MarketingBlocksProps) => {
	return (
		<div className={styles.container} data-component-type='marketingBlocks' data-page-type={page}>
			{title && (
				<Heading tag='h2' className={styles.title}>
					{title}
				</Heading>
			)}
			<Flex gap={4} justify='center' className={styles.blocks}>
				{marketingBlocks.map(({ title, subtitle, buttonText, buttonLink, backgroundImage, button, buttons }, index) => (
					<MarketingBlock
						className={styles.block}
						buttonText={buttonText}
						buttonUrl={buttonLink}
						button={button}
						header={title}
						imageSrc={backgroundImage.url}
						subText={subtitle}
						buttons={buttons}
						key={title}
						blockIndex={index}
					/>
				))}
			</Flex>
		</div>
	);
};

export default MarketingBlocks;
