import { useRouter } from 'next/router';
import { Chevron, Flex, Lozenge, NonRx, Paragraph, Progressive, Readers, SingleVision, Title } from '@components';
import variables from '@styles/export.module.scss';
import { RX_TYPE } from '@utils/constants/base-skus';
import { RxOption } from '@ts/components';
import { useBFContext } from '@context';
import { formatCurrency } from '@utils/shopify';
import { BUILD_FLOW_STEPS, LOCALE_CODES } from '@constants';
import styles from './PrescriptionLensExpansion.module.scss';

type RxOptionsProps = {
	option: RxOption;
	variantPriceDiff: {
		with: { amount: number; locale: (typeof LOCALE_CODES)[keyof typeof LOCALE_CODES]; currencyCode: string };
		without: { amount: number; locale: (typeof LOCALE_CODES)[keyof typeof LOCALE_CODES]; currencyCode: string };
	};
};

const PrescriptionIcons = {
	'single-vision': ({ className }) => <SingleVision className={className} />,
	'non-prescription': ({ className }) => <NonRx className={className} />,
	'readers': ({ className }) => <Readers className={className} />,
	'progressives': ({ className }) => <Progressive className={className} />,
};

const RxOptions = ({ option, variantPriceDiff }: RxOptionsProps) => {
	const { locale } = useRouter();
	const { rxType, step } = useBFContext();
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
	const isOptionSelected = rxType === option.optionValue;
	const isProgressiveSelected = rxType === RX_TYPE.PROGRESSIVE;
	const additionalPrice = isOptionSelected ? variantPriceDiff.without : variantPriceDiff.with;
	const priceCopy = `+${formatCurrency(additionalPrice, showCurr)}`;

	return (
		<Flex fullHeight fullWidth>
			{PrescriptionIcons[option.id]({ className: styles.rxIconLensExpansion })}
			<Flex
				align='center'
				gap={2}
				fullWidth
				style={{ flexGrow: 1, backgroundColor: 'transparent', padding: '1.2rem', borderRadius: '0.4rem' }}
			>
				<Flex column gap={1} align='start' style={{ flexGrow: 1 }}>
					<Flex align='center' gap={2} position={'relative'} fullWidth>
						<Paragraph className={styles['price']}>{priceCopy}</Paragraph>
					</Flex>
					<Title>{option.copy}</Title>
					{option.optionValue === RX_TYPE.PROGRESSIVE && !isProgressiveSelected && (
						<Lozenge
							text='Our Specialty'
							shape='square'
							color={variables.green2}
							backgroundColor={variables.greenLight}
						/>
					)}
					{isOptionSelected ? (
						<Lozenge text='Selected' shape='square' color={variables.green2} backgroundColor={variables.greenLight} />
					) : null}
					<Paragraph style={{ color: variables.gray4 }}>{option.description}</Paragraph>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default RxOptions;
