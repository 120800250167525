import React from 'react';
import Script from 'next/script';

const Storepoint = () => {
	return (
		<>
			<div id='storepoint-container' data-map-id='166073c1a963b8'></div>
			<Script id='storepoint-store-map'>
				{`(function(){
					const a=document.createElement("script");
					a.type="text/javascript";
					a.id="storepoint-script-id";
					a.async=!0;
					a.src="https://cdn.storepoint.co/api/v1/js/166073c1a963b8.js";
					const b=document.getElementsByTagName("script")[0];
					b.parentNode.insertBefore(a,b);
				}())`}
			</Script>
		</>
	);
};

export default Storepoint;
