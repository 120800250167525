import lensHeight from '@public/lens-height.png';
import bridgeWidth from '@public/bridge-width.png';
import lensWidth from '@public/lens-width.png';
import templeLength from '@public/temple-length.png';
import frameWidth from '@public/frame-width.png';
import { Accordian, Flex, Img, Paragraph } from '@components';
import { FrameSizingData } from '@ts/product';

import styles from './HorizontalFrameSizing.module.scss';

type HorizontalFrameSizingProps = {
	frameSizings: FrameSizingData;
	name: string;
	isOpen?: boolean;
	onClick: () => void;
};

const HorizontalFrameSizing = ({ frameSizings, name, isOpen = false, onClick }: HorizontalFrameSizingProps) => {
	const { units, lens, frame } = frameSizings;

	return (
		<Accordian title='Frame Sizing' isOpen={isOpen} onClick={onClick}>
			<Flex pad={4} gap={3} className={styles.container}>
				<div className={styles.contentCard}>
					<Img
						src={frameWidth.src}
						noSrcset
						alt={`Sizing for ${name}`}
						width={250}
						height={125}
						style={{ width: '100%', height: 'auto' }}
					/>
					<Flex column align='start' gap={1}>
						<Paragraph className={styles.title}>Frame Width</Paragraph>
						<Paragraph>{`${frame.width} ${units}`}</Paragraph>
						<Paragraph className={styles.description}>The width of the frame</Paragraph>
					</Flex>
				</div>
				<div className={styles.contentCard}>
					<Img
						src={lensHeight.src}
						noSrcset
						alt={`Sizing for ${name}`}
						width={250}
						height={125}
						style={{ width: '100%', height: 'auto' }}
					/>
					<Flex column align='start' gap={1}>
						<Paragraph className={styles.title}>Lens Height</Paragraph>
						<Paragraph>{`${lens.height} ${units}`}</Paragraph>
						<Paragraph className={styles.description}>The vertical diameter of one lens</Paragraph>
					</Flex>
				</div>
				<div className={styles.contentCard}>
					<Img
						src={bridgeWidth.src}
						noSrcset
						alt={`Sizing for ${name}`}
						width={250}
						height={125}
						style={{ width: '100%', height: 'auto' }}
					/>
					<Flex column align='start' gap={1}>
						<Paragraph className={styles.title}>Bridge Width</Paragraph>
						<Paragraph>{`${frame.bridge + units}`}</Paragraph>
						<Paragraph className={styles.description}>The width of the bridge section</Paragraph>
					</Flex>
				</div>
				<div className={styles.contentCard}>
					<Img
						src={lensWidth.src}
						noSrcset
						alt={`Sizing for ${name}`}
						width={250}
						height={125}
						style={{ width: '100%', height: 'auto' }}
					/>
					<Flex column align='start' gap={1}>
						<Paragraph className={styles.title}>Lens Width</Paragraph>
						<Paragraph>{`${lens.width + units}`}</Paragraph>
						<Paragraph className={styles.description}>The horizontal diameter of one lens</Paragraph>
					</Flex>
				</div>
				<div className={styles.contentCard}>
					<Img
						src={templeLength.src}
						noSrcset
						alt={`Sizing for ${name}`}
						width={250}
						height={125}
						style={{ width: '100%', height: 'auto' }}
					/>
					<Flex column align='start' gap={1}>
						<Paragraph className={styles.title}>Temple Length</Paragraph>
						<Paragraph>{`${frame.temple + units}`}</Paragraph>
						<Paragraph className={styles.description}>
							The length of the temple arm, from the front of the frame to the end tip
						</Paragraph>
					</Flex>
				</div>
			</Flex>
		</Accordian>
	);
};

export default HorizontalFrameSizing;
