export default async function getPortalToken(id: string) {
	try {
		const portalTokenURL = `${process.env.NEXT_PUBLIC_PRIVE_URL}/api/v1/subscribers/${id}/portalLoginToken`;

		const res = await fetch(portalTokenURL, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${process.env.NEXT_PUBLIC_PRIVE_API_TOKEN}`,
			},
		});

		if (!res.ok) {
			throw new Error('Failed to fetch portal token');
		}

		const data = await res.json();

		return data.portalLoginToken;
	} catch (error) {
		throw error;
	}
}
