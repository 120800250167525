import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

const SoundIcon = () => {
	return (
		<AccessibleIcon.Root label='Sound Icon'>
			<svg xmlns='http://www.w3.org/2000/svg' width='25' height='26' viewBox='0 0 25 26' fill='none'>
				<g filter='url(#filter0_d_13652_93887)'>
					<path
						fill-rule='evenodd'
						clip-rule='evenodd'
						d='M16.7548 6.86427C16.5762 6.65359 16.2607 6.62752 16.0501 6.80604C15.8394 6.98456 15.8133 7.30007 15.9918 7.51075C18.6694 10.6707 18.6694 15.3263 15.9918 18.4862C15.8133 18.6969 15.8394 19.0124 16.0501 19.1909C16.2607 19.3694 16.5762 19.3434 16.7548 19.1327C19.7485 15.5997 19.7485 10.3973 16.7548 6.86427ZM11.5 7.00001V19L7.5 16H5.5C4.94772 16 4.5 15.5523 4.5 15V11C4.5 10.4477 4.94772 10 5.5 10H7.5L11.5 7.00001ZM14.223 9.26342C14.4529 9.11045 14.7633 9.17283 14.9163 9.40273C16.3614 11.5748 16.3614 14.4225 14.9163 16.5945C14.7633 16.8244 14.4529 16.8868 14.223 16.7338C13.9931 16.5809 13.9308 16.2705 14.0837 16.0406C15.3056 14.2041 15.3056 11.7932 14.0837 9.95666C13.9308 9.72676 13.9931 9.41638 14.223 9.26342Z'
						fill='white'
					/>
				</g>
				<defs>
					<filter
						id='filter0_d_13652_93887'
						x='-2'
						y='-1.5'
						width='32'
						height='32'
						filterUnits='userSpaceOnUse'
						color-interpolation-filters='sRGB'
					>
						<feFlood flood-opacity='0' result='BackgroundImageFix' />
						<feColorMatrix
							in='SourceAlpha'
							type='matrix'
							values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
							result='hardAlpha'
						/>
						<feOffset dx='2' dy='2' />
						<feGaussianBlur stdDeviation='2' />
						<feComposite in2='hardAlpha' operator='out' />
						<feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0' />
						<feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_13652_93887' />
						<feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_13652_93887' result='shape' />
					</filter>
				</defs>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default SoundIcon;
