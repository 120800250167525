const customerActivateMutation = /* GraphQL */ `
	mutation customerActivate($id: ID!, $input: CustomerActivateInput!) {
		customerActivate(id: $id, input: $input) {
			customerAccessToken {
				accessToken
				expiresAt
			}
			customerUserErrors {
				code
				field
				message
			}
		}
	}
`;
export default customerActivateMutation;
