import { Flex } from '@components';
import styles from './HoverCard.module.scss';

const HoverCard = ({ children }) => {
	return (
		<Flex column center className={styles.container} gap={3}>
			{children}
		</Flex>
	);
};
export default HoverCard;
